import RootStore from "./RootStore"
import {
    Configuration,
    DeviceApi,
    DeviceGroupApi,
    Middleware,
    RuleChainApi,
    SensorApi,
    TenantApi,
    DeviceModelApi,
    UserApi,
    AlarmApi,
    TimeSeriesDataApi,
    CommandApi,
    DriverApi,
    StatsApi,
    ImportApi,
    HostStatsApi
} from '../api'
import config from '../config'
import localForage from "localforage"
import i18n from '../i18n';

class ApiStore {
    rootStore: RootStore
    deviceApi: DeviceApi
    tenantApi: TenantApi
    sensorApi: SensorApi
    deviceGroupApi: DeviceGroupApi
    ruleChainApi: RuleChainApi
    alarmApi: AlarmApi
    deviceModelApi: DeviceModelApi
    userApi: UserApi
    timeSeriesDataApi: TimeSeriesDataApi
    command: CommandApi
    driver: DriverApi
    statsApi: StatsApi
    importApi: ImportApi
    hostStatsApi: HostStatsApi


    localForage: LocalForage

    token = "--"



    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.localForage = localForage.createInstance({

            name: 'yhsde'
        })

        const oldToken = localStorage.getItem(config.tokenKey)
        this.token = oldToken ? oldToken! : "--"

        let conf = new Configuration({
            basePath: config.api_url,
            apiKey: this.getToken
        })



        this.deviceApi = new DeviceApi(conf).withMiddleware(this.apiMiddleware)
        this.tenantApi = new TenantApi(conf).withMiddleware(this.apiMiddleware)
        this.sensorApi = new SensorApi(conf).withMiddleware(this.apiMiddleware)
        this.deviceGroupApi = new DeviceGroupApi(conf).withMiddleware(this.apiMiddleware)
        this.ruleChainApi = new RuleChainApi(conf).withMiddleware(this.apiMiddleware)
        this.alarmApi = new AlarmApi(conf).withMiddleware(this.apiMiddleware)
        this.deviceModelApi = new DeviceModelApi(conf).withMiddleware(this.apiMiddleware)
        this.userApi = new UserApi(conf).withMiddleware(this.apiMiddleware)
        this.timeSeriesDataApi = new TimeSeriesDataApi(conf).withMiddleware(this.apiMiddleware)
        this.command = new CommandApi(conf).withMiddleware(this.apiMiddleware)
        this.driver = new DriverApi(conf).withMiddleware(this.apiMiddleware)
        this.statsApi = new StatsApi(conf).withMiddleware(this.apiMiddleware)
        this.importApi = new ImportApi(conf).withMiddleware(this.apiMiddleware)
        this.hostStatsApi = new HostStatsApi(conf).withMiddleware(this.apiMiddleware)

    }

    getToken = () => {
        return "bearer " + this.token
    }

    setTokenDto = (dto: any) => {
        this.token = dto.accessToken!


    }

    isAlreadyFetchingAccessToken = false;
    subscribers: any[] = [];

    apiMiddleware: Middleware = {
        /*
        pre: async (e) => {
            const { url, init } = e
            let newUrl = url
            if (this.rootStore.user.ImpersonationTenantId) {
                if (!init.headers) {
                    init.headers = {}
                }
                init.headers["X-Tenant-Id"] = this.rootStore.user.ImpersonationTenantId;

            }


            return { url: newUrl, init }
        },*/
        post: async (e) => {
            const { response } = e
            if (response) {
                if (response.status === 401) {

                    this.rootStore.authStore.logout()

                } else if (response.status === 500) {

                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.serverError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                } else if (response.status === 409) {
                    let text = await response.text()
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.deleteError", { res: text }),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                } else if (response.status === 403) {
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.permissionError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                } else if (response.status === 501) {
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.notImplemented"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                } else if (response.status === 599) {
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.connectionError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                } else if (response.status === 230) {
                    this.rootStore.notification.enqueue({
                        message: i18n.t("common.errors.atsPairError"),
                        options: {
                            variant: 'error',
                            preventDuplicate: true
                        }
                    })
                    return e.response

                }


            }
            return e.response
        }
    }

}

export default ApiStore