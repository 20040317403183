/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";


export interface GetConnectedDevicesRequest {
    xTenantId?: string;
}

export interface GetIncomingMessageBytesRequest {
    xTenantId?: string;
}

export interface GetMessageMeterRequest {
    xTenantId?: string;
}

export interface GetOutgoingMessageBytesRequest {
    xTenantId?: string;
}

export interface GetTimeSeriesMeterRequest {
    xTenantId?: string;
}

/**
 * HostStatsApi - interface
 * 
 * @export
 * @interface HostStatsApiInterface
 */
export interface HostStatsApiInterface {
    /**
     * 
     * @summary Bağlı cihaz sayısı (Permission.Host.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApiInterface
     */
    getConnectedDevicesRaw(requestParameters: GetConnectedDevicesRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Bağlı cihaz sayısı (Permission.Host.Stats.List)
     */
    getConnectedDevices(requestParameters: GetConnectedDevicesRequest): Promise<any>;

    /**
     * 
     * @summary Gelen  Mesaj bytes (Permission.Host.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApiInterface
     */
    getIncomingMessageBytesRaw(requestParameters: GetIncomingMessageBytesRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Gelen  Mesaj bytes (Permission.Host.Stats.List)
     */
    getIncomingMessageBytes(requestParameters: GetIncomingMessageBytesRequest): Promise<any>;

    /**
     * 
     * @summary Mesaj sayıları (Permission.Host.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApiInterface
     */
    getMessageMeterRaw(requestParameters: GetMessageMeterRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Mesaj sayıları (Permission.Host.Stats.List)
     */
    getMessageMeter(requestParameters: GetMessageMeterRequest): Promise<any>;

    /**
     * 
     * @summary Giden  Mesaj bytes (Permission.Host.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApiInterface
     */
    getOutgoingMessageBytesRaw(requestParameters: GetOutgoingMessageBytesRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Giden  Mesaj bytes (Permission.Host.Stats.List)
     */
    getOutgoingMessageBytes(requestParameters: GetOutgoingMessageBytesRequest): Promise<any>;

    /**
     * 
     * @summary Timeseries data (Permission.Host.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostStatsApiInterface
     */
    getTimeSeriesMeterRaw(requestParameters: GetTimeSeriesMeterRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Timeseries data (Permission.Host.Stats.List)
     */
    getTimeSeriesMeter(requestParameters: GetTimeSeriesMeterRequest): Promise<any>;

}

/**
 * 
 */
export class HostStatsApi extends runtime.BaseAPI implements HostStatsApiInterface {

    /**
     * Bağlı cihaz sayısı (Permission.Host.Stats.List)
     */
    async getConnectedDevicesRaw(requestParameters: GetConnectedDevicesRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/HostStats/connecteddevices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Bağlı cihaz sayısı (Permission.Host.Stats.List)
     */
    async getConnectedDevices(requestParameters: GetConnectedDevicesRequest): Promise<any> {
        const response = await this.getConnectedDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gelen  Mesaj bytes (Permission.Host.Stats.List)
     */
    async getIncomingMessageBytesRaw(requestParameters: GetIncomingMessageBytesRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/HostStats/incomingmessagebytes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Gelen  Mesaj bytes (Permission.Host.Stats.List)
     */
    async getIncomingMessageBytes(requestParameters: GetIncomingMessageBytesRequest): Promise<any> {
        const response = await this.getIncomingMessageBytesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Mesaj sayıları (Permission.Host.Stats.List)
     */
    async getMessageMeterRaw(requestParameters: GetMessageMeterRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/HostStats/messagemeter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Mesaj sayıları (Permission.Host.Stats.List)
     */
    async getMessageMeter(requestParameters: GetMessageMeterRequest): Promise<any> {
        const response = await this.getMessageMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Giden  Mesaj bytes (Permission.Host.Stats.List)
     */
    async getOutgoingMessageBytesRaw(requestParameters: GetOutgoingMessageBytesRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/HostStats/outgoingmessagebytes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Giden  Mesaj bytes (Permission.Host.Stats.List)
     */
    async getOutgoingMessageBytes(requestParameters: GetOutgoingMessageBytesRequest): Promise<any> {
        const response = await this.getOutgoingMessageBytesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Timeseries data (Permission.Host.Stats.List)
     */
    async getTimeSeriesMeterRaw(requestParameters: GetTimeSeriesMeterRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/HostStats/timeseries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Timeseries data (Permission.Host.Stats.List)
     */
    async getTimeSeriesMeter(requestParameters: GetTimeSeriesMeterRequest): Promise<any> {
        const response = await this.getTimeSeriesMeterRaw(requestParameters);
        return await response.value();
    }

}
