/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Cihaz bilgileri; Cihazın bağlantı durumu ve cihaz üzerindeki ek bilgiler
 * @export
 * @interface DeviceStateDTO
 */
export interface DeviceStateDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceStateDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    updatedDate?: moment.Moment;

    /**
     * Bağlantı durumu
     * @type {boolean}
     * @memberof DeviceStateDTO
     */
    connected?: boolean;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    lastConnectionDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    lastDisConnectionDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    messageDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceStateDTO
     */
    lastMessageDate?: moment.Moment | null;

    /**
     * Bağlantı adresi
     * @type {string}
     * @memberof DeviceStateDTO
     */
    connectionAddress?: string | null;

    /**
     * Ek bilgiler
     * @type {any}
     * @memberof DeviceStateDTO
     */
    custom?: any | null;

}

export function DeviceStateDTOFromJSON(json: any): DeviceStateDTO {
    return DeviceStateDTOFromJSONTyped(json, false);
}

export function DeviceStateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceStateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'connected': !exists(json, 'connected') ? undefined : json['connected'],
        'lastConnectionDate': !exists(json, 'lastConnectionDate') ? undefined : (moment(json['lastConnectionDate'])),

        'lastDisConnectionDate': !exists(json, 'lastDisConnectionDate') ? undefined : (moment(json['lastDisConnectionDate'])),

        'messageDate': !exists(json, 'messageDate') ? undefined : (moment(json['messageDate'])),

        'lastMessageDate': !exists(json, 'lastMessageDate') ? undefined : (json['lastMessageDate'] === null ? null : moment(json['lastMessageDate'])),

        'connectionAddress': !exists(json, 'connectionAddress') ? undefined : json['connectionAddress'],
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
    };
}

export function DeviceStateDTOToJSON(value?: DeviceStateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'connected': value.connected,
        'lastConnectionDate': value.lastConnectionDate === undefined ? undefined : (value.lastConnectionDate.toISOString()),
        'lastDisConnectionDate': value.lastDisConnectionDate === undefined ? undefined : (value.lastDisConnectionDate.toISOString()),
        'messageDate': value.messageDate === undefined ? undefined : (value.messageDate.toISOString()),
        'lastMessageDate': value.lastMessageDate === undefined ? undefined : (value.lastMessageDate === null ? null : value.lastMessageDate.toISOString()),
        'connectionAddress': value.connectionAddress,
        'custom': value.custom,
    };
}


