/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    Claim,
    ClaimFromJSON,
    ClaimFromJSONTyped,
    ClaimToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClaimsIdentity
 */
export interface ClaimsIdentity {
    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    authenticationType?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIdentity
     */
    readonly isAuthenticated?: boolean;

    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof ClaimsIdentity
     */
    actor?: ClaimsIdentity;

    /**
     * 
     * @type {any}
     * @memberof ClaimsIdentity
     */
    bootstrapContext?: any | null;

    /**
     * 
     * @type {Array<Claim>}
     * @memberof ClaimsIdentity
     */
    claims?: Array<Claim> | null;

    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    label?: string | null;

    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    readonly name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    readonly nameClaimType?: string | null;

    /**
     * 
     * @type {string}
     * @memberof ClaimsIdentity
     */
    readonly roleClaimType?: string | null;

}

export function ClaimsIdentityFromJSON(json: any): ClaimsIdentity {
    return ClaimsIdentityFromJSONTyped(json, false);
}

export function ClaimsIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimsIdentity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authenticationType': !exists(json, 'authenticationType') ? undefined : json['authenticationType'],
        'isAuthenticated': !exists(json, 'isAuthenticated') ? undefined : json['isAuthenticated'],
        'actor': !exists(json, 'actor') ? undefined : ClaimsIdentityFromJSON(json['actor']),
        'bootstrapContext': !exists(json, 'bootstrapContext') ? undefined : json['bootstrapContext'],
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(ClaimFromJSON)),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameClaimType': !exists(json, 'nameClaimType') ? undefined : json['nameClaimType'],
        'roleClaimType': !exists(json, 'roleClaimType') ? undefined : json['roleClaimType'],
    };
}

export function ClaimsIdentityToJSON(value?: ClaimsIdentity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authenticationType': value.authenticationType,
        'actor': ClaimsIdentityToJSON(value.actor),
        'bootstrapContext': value.bootstrapContext,
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(ClaimToJSON)),
        'label': value.label,
    };
}


