/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DeviceDeviceGroupDTO
 */
export interface DeviceDeviceGroupDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceGroupDTO
     */
    deviceId?: Array<string> | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceGroupDTO
     */
    deviceGroupId?: string;

}

export function DeviceDeviceGroupDTOFromJSON(json: any): DeviceDeviceGroupDTO {
    return DeviceDeviceGroupDTOFromJSONTyped(json, false);
}

export function DeviceDeviceGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceDeviceGroupDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
    };
}

export function DeviceDeviceGroupDTOToJSON(value?: DeviceDeviceGroupDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'deviceGroupId': value.deviceGroupId,
    };
}


