/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    DeviceGroupNameDTO,
    DeviceGroupNameDTOFromJSON,
    DeviceGroupNameDTOFromJSONTyped,
    DeviceGroupNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    description?: string | null;

    /**
     * Cihazın gerçek/fiziksel Id
     * @type {string}
     * @memberof DeviceDTO
     */
    registerId?: string | null;

    /**
     * Cihazın Iot.Data.Models.DeviceModel Id
     * @type {string}
     * @memberof DeviceDTO
     */
    deviceModelId?: string;

    /**
     * Cihazın bulunduğu grup listesi
     * @type {Array<DeviceGroupNameDTO>}
     * @memberof DeviceDTO
     */
    deviceGroups?: Array<DeviceGroupNameDTO> | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    id?: string;

    /**
     * 
     * @type {Date}
     * @memberof DeviceDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceDTO
     */
    updatedDate?: moment.Moment;

    /**
     * Cihaz model ismi
     * @type {string}
     * @memberof DeviceDTO
     */
    deviceModelName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    tenantName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    tenantId?: string;

}

export function DeviceDTOFromJSON(json: any): DeviceDTO {
    return DeviceDTOFromJSONTyped(json, false);
}

export function DeviceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'registerId': !exists(json, 'registerId') ? undefined : json['registerId'],
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'deviceGroups': !exists(json, 'deviceGroups') ? undefined : (json['deviceGroups'] === null ? null : (json['deviceGroups'] as Array<any>).map(DeviceGroupNameDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'deviceModelName': !exists(json, 'deviceModelName') ? undefined : json['deviceModelName'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function DeviceDTOToJSON(value?: DeviceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'registerId': value.registerId,
        'deviceModelId': value.deviceModelId,
        'deviceGroups': value.deviceGroups === undefined ? undefined : (value.deviceGroups === null ? null : (value.deviceGroups as Array<any>).map(DeviceGroupNameDTOToJSON)),
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'deviceModelName': value.deviceModelName,
        'tenantName': value.tenantName,
        'tenantId': value.tenantId,
    };
}


