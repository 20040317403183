import RootStore from "./RootStore"
import config from '../config'
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import i18n from "../i18n";

class SignalRStore {
    rootStore: RootStore


    //signalr
    device: HubConnection




    constructor(rootStore: RootStore) {
        this.rootStore = rootStore


        this.device = new HubConnectionBuilder()
            .withUrl(`${config.signalr}/hubs/device`, { withCredentials: false, accessTokenFactory: this.getToken })
            .withAutomaticReconnect()
            .build();
    }

    connectSignalR = async () => {
        try {

            await this.device.stop()
            await this.device.start()
        } catch (e) {
            if (e.statusCode && e.statusCode === 401) {
                this.rootStore.notification.enqueue({
                    message: i18n.t("common.errors.sessionDeath"),
                    options: {
                        variant: 'error',
                        preventDuplicate: true
                    }
                })
                this.rootStore.authStore.logout()
            }
        }
    }

    disConnectSignalR = async () => {
        await this.device.stop()
    }

    getToken = () => {
        return this.rootStore.api.token
    }


}

export default SignalRStore