/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Cihaz modeli
 * @export
 * @interface DeviceModelPostDTO
 */
export interface DeviceModelPostDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceModelPostDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelPostDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelPostDTO
     */
    driverClassName?: string | null;

}

export function DeviceModelPostDTOFromJSON(json: any): DeviceModelPostDTO {
    return DeviceModelPostDTOFromJSONTyped(json, false);
}

export function DeviceModelPostDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModelPostDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'driverClassName': !exists(json, 'driverClassName') ? undefined : json['driverClassName'],
    };
}

export function DeviceModelPostDTOToJSON(value?: DeviceModelPostDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'driverClassName': value.driverClassName,
    };
}


