/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface CustomFilter
 */
export interface CustomFilter {
    /**
     * 
     * @type {number}
     * @memberof CustomFilter
     */
    long1?: number;

    /**
     * 
     * @type {number}
     * @memberof CustomFilter
     */
    long2?: number;

    /**
     * 
     * @type {number}
     * @memberof CustomFilter
     */
    long3?: number;

    /**
     * 
     * @type {string}
     * @memberof CustomFilter
     */
    string1?: string | null;

    /**
     * 
     * @type {string}
     * @memberof CustomFilter
     */
    string2?: string | null;

    /**
     * 
     * @type {string}
     * @memberof CustomFilter
     */
    string3?: string | null;

}

export function CustomFilterFromJSON(json: any): CustomFilter {
    return CustomFilterFromJSONTyped(json, false);
}

export function CustomFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'long1': !exists(json, 'long1') ? undefined : json['long1'],
        'long2': !exists(json, 'long2') ? undefined : json['long2'],
        'long3': !exists(json, 'long3') ? undefined : json['long3'],
        'string1': !exists(json, 'string1') ? undefined : json['string1'],
        'string2': !exists(json, 'string2') ? undefined : json['string2'],
        'string3': !exists(json, 'string3') ? undefined : json['string3'],
    };
}

export function CustomFilterToJSON(value?: CustomFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'long1': value.long1,
        'long2': value.long2,
        'long3': value.long3,
        'string1': value.string1,
        'string2': value.string2,
        'string3': value.string3,
    };
}


