/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    GuidIdentityRoleClaim,
    GuidIdentityRoleClaimFromJSON,
    GuidIdentityRoleClaimFromJSONTyped,
    GuidIdentityRoleClaimToJSON,
} from './';

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Role
     */
    normalizedName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Role
     */
    concurrencyStamp?: string | null;

    /**
     * 
     * @type {Array<GuidIdentityRoleClaim>}
     * @memberof Role
     */
    claims?: Array<GuidIdentityRoleClaim> | null;

    /**
     * 
     * @type {string}
     * @memberof Role
     */
    tenantId?: string;

}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'normalizedName': !exists(json, 'normalizedName') ? undefined : json['normalizedName'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(GuidIdentityRoleClaimFromJSON)),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'normalizedName': value.normalizedName,
        'concurrencyStamp': value.concurrencyStamp,
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(GuidIdentityRoleClaimToJSON)),
        'tenantId': value.tenantId,
    };
}


