/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
} from './';

/**
 * Cihaz bilgileri
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof Device
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceModelId?: string;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    description?: string | null;

    /**
     * Cihazın gerçek/fiziksel ID sı. Cihaz bu Id authenticate olur
     * @type {string}
     * @memberof Device
     */
    registerId?: string | null;

    /**
     * Cihazın müşteri tarafından eklenebilecek ek bilgiler
     * @type {any}
     * @memberof Device
     */
    customAttributes?: any | null;

    /**
     * 
     * @type {string}
     * @memberof Device
     */
    tenantId?: string;

}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'registerId': !exists(json, 'registerId') ? undefined : json['registerId'],
        'customAttributes': !exists(json, 'customAttributes') ? undefined : json['customAttributes'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'deviceModelId': value.deviceModelId,
        'name': value.name,
        'description': value.description,
        'registerId': value.registerId,
        'customAttributes': value.customAttributes,
        'tenantId': value.tenantId,
    };
}


