/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleEngineAlarmConfiguration,
    RuleEngineAlarmConfigurationFromJSON,
    RuleEngineAlarmConfigurationFromJSONTyped,
    RuleEngineAlarmConfigurationToJSON,
} from './';

/**
 * Kural motoru alarmları, Cihaz verileri üzerinde basit kurallar ile Iot.Data.Models.AlarmEvent oluşturur.
 * @export
 * @interface RuleEngineAlarmPostDTO
 */
export interface RuleEngineAlarmPostDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmPostDTO
     */
    deviceId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmPostDTO
     */
    deviceGroupId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmPostDTO
     */
    deviceModelId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmPostDTO
     */
    name?: string | null;

    /**
     * 
     * @type {RuleEngineAlarmConfiguration}
     * @memberof RuleEngineAlarmPostDTO
     */
    _configuration?: RuleEngineAlarmConfiguration;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmPostDTO
     */
    description?: string | null;

}

export function RuleEngineAlarmPostDTOFromJSON(json: any): RuleEngineAlarmPostDTO {
    return RuleEngineAlarmPostDTOFromJSONTyped(json, false);
}

export function RuleEngineAlarmPostDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmPostDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_configuration': !exists(json, 'configuration') ? undefined : RuleEngineAlarmConfigurationFromJSON(json['configuration']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RuleEngineAlarmPostDTOToJSON(value?: RuleEngineAlarmPostDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'deviceGroupId': value.deviceGroupId,
        'deviceModelId': value.deviceModelId,
        'name': value.name,
        'configuration': RuleEngineAlarmConfigurationToJSON(value._configuration),
        'description': value.description,
    };
}


