/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    ConnectionProtocols,
    ConnectionProtocolsFromJSON,
    ConnectionProtocolsToJSON,
    Driver,
    DriverFromJSON,
    DriverToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteDriverRequest {
    className: string;
    xTenantId?: string;
}

export interface GetDriverRequest {
    className: string;
    xTenantId?: string;
}

export interface GetDriversRequest {
    protocol?: ConnectionProtocols;
    xTenantId?: string;
}

export interface PostDriverRequest {
    xTenantId?: string;
    driver?: Driver;
}

export interface PutDriverRequest {
    className: string;
    xTenantId?: string;
    driver?: Driver;
}

/**
 * DriverApi - interface
 * 
 * @export
 * @interface DriverApiInterface
 */
export interface DriverApiInterface {
    /**
     * 
     * @summary Driver  Silme (Permission.Host.Driver.Delete)
     * @param {string} className 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApiInterface
     */
    deleteDriverRaw(requestParameters: DeleteDriverRequest): Promise<runtime.ApiResponse<Driver>>;

    /**
     * Driver  Silme (Permission.Host.Driver.Delete)
     */
    deleteDriver(requestParameters: DeleteDriverRequest): Promise<Driver>;

    /**
     * 
     * @summary Driver Detay (Permission.Host.Driver.List)
     * @param {string} className 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApiInterface
     */
    getDriverRaw(requestParameters: GetDriverRequest): Promise<runtime.ApiResponse<Driver>>;

    /**
     * Driver Detay (Permission.Host.Driver.List)
     */
    getDriver(requestParameters: GetDriverRequest): Promise<Driver>;

    /**
     * 
     * @summary Driver Listeleme (Permission.Host.Driver.List)
     * @param {ConnectionProtocols} [protocol] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApiInterface
     */
    getDriversRaw(requestParameters: GetDriversRequest): Promise<runtime.ApiResponse<Array<Driver>>>;

    /**
     * Driver Listeleme (Permission.Host.Driver.List)
     */
    getDrivers(requestParameters: GetDriversRequest): Promise<Array<Driver>>;

    /**
     * Driver ekleme operasyonu öncesi, driver implentasyonu hazır olmalıdır.
     * @summary Driver Ekleme (Permission.Host.Driver.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {Driver} [driver] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApiInterface
     */
    postDriverRaw(requestParameters: PostDriverRequest): Promise<runtime.ApiResponse<Driver>>;

    /**
     * Driver ekleme operasyonu öncesi, driver implentasyonu hazır olmalıdır.
     * Driver Ekleme (Permission.Host.Driver.Add)
     */
    postDriver(requestParameters: PostDriverRequest): Promise<Driver>;

    /**
     * Driver güncelleme gatewayın yeniden başlamasına neden olur, güncellenen driveri kullanan cihazların bağlantısı  kopacaktır.
     * @summary Driver Güncelleme (Permission.Host.Driver.Edit)
     * @param {string} className 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {Driver} [driver] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApiInterface
     */
    putDriverRaw(requestParameters: PutDriverRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Driver güncelleme gatewayın yeniden başlamasına neden olur, güncellenen driveri kullanan cihazların bağlantısı  kopacaktır.
     * Driver Güncelleme (Permission.Host.Driver.Edit)
     */
    putDriver(requestParameters: PutDriverRequest): Promise<void>;

}

/**
 * 
 */
export class DriverApi extends runtime.BaseAPI implements DriverApiInterface {

    /**
     * Driver  Silme (Permission.Host.Driver.Delete)
     */
    async deleteDriverRaw(requestParameters: DeleteDriverRequest): Promise<runtime.ApiResponse<Driver>> {
        if (requestParameters.className === null || requestParameters.className === undefined) {
            throw new runtime.RequiredError('className','Required parameter requestParameters.className was null or undefined when calling deleteDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Driver/{className}`.replace(`{${"className"}}`, encodeURIComponent(String(requestParameters.className))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     * Driver  Silme (Permission.Host.Driver.Delete)
     */
    async deleteDriver(requestParameters: DeleteDriverRequest): Promise<Driver> {
        const response = await this.deleteDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Driver Detay (Permission.Host.Driver.List)
     */
    async getDriverRaw(requestParameters: GetDriverRequest): Promise<runtime.ApiResponse<Driver>> {
        if (requestParameters.className === null || requestParameters.className === undefined) {
            throw new runtime.RequiredError('className','Required parameter requestParameters.className was null or undefined when calling getDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Driver/{className}`.replace(`{${"className"}}`, encodeURIComponent(String(requestParameters.className))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     * Driver Detay (Permission.Host.Driver.List)
     */
    async getDriver(requestParameters: GetDriverRequest): Promise<Driver> {
        const response = await this.getDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Driver Listeleme (Permission.Host.Driver.List)
     */
    async getDriversRaw(requestParameters: GetDriversRequest): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.protocol !== undefined) {
            queryParameters['protocol'] = requestParameters.protocol;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Driver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     * Driver Listeleme (Permission.Host.Driver.List)
     */
    async getDrivers(requestParameters: GetDriversRequest): Promise<Array<Driver>> {
        const response = await this.getDriversRaw(requestParameters);
        return await response.value();
    }

    /**
     * Driver ekleme operasyonu öncesi, driver implentasyonu hazır olmalıdır.
     * Driver Ekleme (Permission.Host.Driver.Add)
     */
    async postDriverRaw(requestParameters: PostDriverRequest): Promise<runtime.ApiResponse<Driver>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Driver`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverToJSON(requestParameters.driver),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     * Driver ekleme operasyonu öncesi, driver implentasyonu hazır olmalıdır.
     * Driver Ekleme (Permission.Host.Driver.Add)
     */
    async postDriver(requestParameters: PostDriverRequest): Promise<Driver> {
        const response = await this.postDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Driver güncelleme gatewayın yeniden başlamasına neden olur, güncellenen driveri kullanan cihazların bağlantısı  kopacaktır.
     * Driver Güncelleme (Permission.Host.Driver.Edit)
     */
    async putDriverRaw(requestParameters: PutDriverRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.className === null || requestParameters.className === undefined) {
            throw new runtime.RequiredError('className','Required parameter requestParameters.className was null or undefined when calling putDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Driver/{className}`.replace(`{${"className"}}`, encodeURIComponent(String(requestParameters.className))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverToJSON(requestParameters.driver),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Driver güncelleme gatewayın yeniden başlamasına neden olur, güncellenen driveri kullanan cihazların bağlantısı  kopacaktır.
     * Driver Güncelleme (Permission.Host.Driver.Edit)
     */
    async putDriver(requestParameters: PutDriverRequest): Promise<void> {
        await this.putDriverRaw(requestParameters);
    }

}
