/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleNodeDTO,
    RuleNodeDTOFromJSON,
    RuleNodeDTOFromJSONTyped,
    RuleNodeDTOToJSON,
} from './';

/**
 * Iot.Data.Models.RuleChain içerisindeki Iot.Data.Models.RuleNode ilişkileri
 * @export
 * @interface RuleNodeRelationDTO
 */
export interface RuleNodeRelationDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationDTO
     */
    ruleChainId?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationDTO
     */
    fromId?: string;

    /**
     * 
     * @type {RuleNodeDTO}
     * @memberof RuleNodeRelationDTO
     */
    from?: RuleNodeDTO;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationDTO
     */
    toId?: string;

    /**
     * 
     * @type {RuleNodeDTO}
     * @memberof RuleNodeRelationDTO
     */
    to?: RuleNodeDTO;

    /**
     * İlişki tipi, çok çıkışlı nodelarda çıkışları ayırt etmek için.
     * @type {string}
     * @memberof RuleNodeRelationDTO
     */
    type?: string | null;

}

export function RuleNodeRelationDTOFromJSON(json: any): RuleNodeRelationDTO {
    return RuleNodeRelationDTOFromJSONTyped(json, false);
}

export function RuleNodeRelationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNodeRelationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        'fromId': !exists(json, 'fromId') ? undefined : json['fromId'],
        'from': !exists(json, 'from') ? undefined : RuleNodeDTOFromJSON(json['from']),
        'toId': !exists(json, 'toId') ? undefined : json['toId'],
        'to': !exists(json, 'to') ? undefined : RuleNodeDTOFromJSON(json['to']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeRelationDTOToJSON(value?: RuleNodeRelationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleChainId': value.ruleChainId,
        'fromId': value.fromId,
        'from': RuleNodeDTOToJSON(value.from),
        'toId': value.toId,
        'to': RuleNodeDTOToJSON(value.to),
        'type': value.type,
    };
}


