/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface RuleNodeUpdateDTO
 */
export interface RuleNodeUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeUpdateDTO
     */
    id: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeUpdateDTO
     */
    ruleChainId?: string;

    /**
     * 
     * @type {any}
     * @memberof RuleNodeUpdateDTO
     */
    _configuration?: any | null;

    /**
     * Arayüz için Ek parametreler.
     * @type {any}
     * @memberof RuleNodeUpdateDTO
     */
    uiConfiguration?: any | null;

    /**
     * Görsel ismi
     * @type {string}
     * @memberof RuleNodeUpdateDTO
     */
    name?: string | null;

    /**
     * Node hangi class ile çalıştığını belirtir. Rule engine içersinde implementasyonu olmalıdır.
     * TODO: enum yapılabilir
     * @type {string}
     * @memberof RuleNodeUpdateDTO
     */
    type?: string | null;

}

export function RuleNodeUpdateDTOFromJSON(json: any): RuleNodeUpdateDTO {
    return RuleNodeUpdateDTOFromJSONTyped(json, false);
}

export function RuleNodeUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNodeUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'uiConfiguration': !exists(json, 'uiConfiguration') ? undefined : json['uiConfiguration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeUpdateDTOToJSON(value?: RuleNodeUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ruleChainId': value.ruleChainId,
        'configuration': value._configuration,
        'uiConfiguration': value.uiConfiguration,
        'name': value.name,
        'type': value.type,
    };
}


