/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Cihaz tarafından oluşturulan alarmlar. Iot.Data.Models.AlarmEvent ile benzer yapıdadır
 * @export
 * @interface DeviceEventDTO
 */
export interface DeviceEventDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    id?: string;

    /**
     * 
     * @type {Date}
     * @memberof DeviceEventDTO
     */
    timestamp?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    deviceId?: string;

    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    deviceName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    typeKey?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof DeviceEventDTO
     */
    bool?: boolean | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    str?: string | null;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTO
     */
    _long?: number | null;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTO
     */
    _float?: number | null;

    /**
     * 
     * @type {any}
     * @memberof DeviceEventDTO
     */
    json?: any | null;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTO
     */
    lat?: number | null;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTO
     */
    lon?: number | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceEventDTO
     */
    tenantId?: string;

}

export function DeviceEventDTOFromJSON(json: any): DeviceEventDTO {
    return DeviceEventDTOFromJSONTyped(json, false);
}

export function DeviceEventDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceEventDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (moment(json['timestamp'])),

        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'deviceName': !exists(json, 'deviceName') ? undefined : json['deviceName'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'bool': !exists(json, 'bool') ? undefined : json['bool'],
        'str': !exists(json, 'str') ? undefined : json['str'],
        '_long': !exists(json, 'long') ? undefined : json['long'],
        '_float': !exists(json, 'float') ? undefined : json['float'],
        'json': !exists(json, 'json') ? undefined : json['json'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function DeviceEventDTOToJSON(value?: DeviceEventDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'deviceId': value.deviceId,
        'deviceName': value.deviceName,
        'typeKey': value.typeKey,
        'bool': value.bool,
        'str': value.str,
        'long': value._long,
        'float': value._float,
        'json': value.json,
        'lat': value.lat,
        'lon': value.lon,
        'tenantId': value.tenantId,
    };
}


