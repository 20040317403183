/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    DeviceModel,
    DeviceModelFromJSON,
    DeviceModelToJSON,
    DeviceModelDTO,
    DeviceModelDTOFromJSON,
    DeviceModelDTOToJSON,
    DeviceModelPostDTO,
    DeviceModelPostDTOFromJSON,
    DeviceModelPostDTOToJSON,
    DeviceModelUpdateDTO,
    DeviceModelUpdateDTOFromJSON,
    DeviceModelUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteDeviceModelRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceModelRequest {
    id: string;
    xTenantId?: string;
}

export interface GetDeviceModelSuggestionsRequest {
    xTenantId?: string;
}

export interface GetDeviceModelsRequest {
    xTenantId?: string;
}

export interface PostDeviceModelRequest {
    xTenantId?: string;
    deviceModelPostDTO?: DeviceModelPostDTO;
}

export interface PutDeviceModelRequest {
    id: string;
    xTenantId?: string;
    deviceModelUpdateDTO?: DeviceModelUpdateDTO;
}

/**
 * DeviceModelApi - interface
 * 
 * @export
 * @interface DeviceModelApiInterface
 */
export interface DeviceModelApiInterface {
    /**
     * 
     * @summary Cihaz Model Silme (Permission.DeviceModel.Delete)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    deleteDeviceModelRaw(requestParameters: DeleteDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModel>>;

    /**
     * Cihaz Model Silme (Permission.DeviceModel.Delete)
     */
    deleteDeviceModel(requestParameters: DeleteDeviceModelRequest): Promise<DeviceModel>;

    /**
     * 
     * @summary Cihaz Model Detay (Permission.DeviceModel.List)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    getDeviceModelRaw(requestParameters: GetDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModelDTO>>;

    /**
     * Cihaz Model Detay (Permission.DeviceModel.List)
     */
    getDeviceModel(requestParameters: GetDeviceModelRequest): Promise<DeviceModelDTO>;

    /**
     * 
     * @summary Cihaz Model öneri Listeleme (Permission.DeviceModel.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    getDeviceModelSuggestionsRaw(requestParameters: GetDeviceModelSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     * Cihaz Model öneri Listeleme (Permission.DeviceModel.List)
     */
    getDeviceModelSuggestions(requestParameters: GetDeviceModelSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @summary Cihaz Model Listeleme (Permission.DeviceModel.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    getDeviceModelsRaw(requestParameters: GetDeviceModelsRequest): Promise<runtime.ApiResponse<Array<DeviceModelDTO>>>;

    /**
     * Cihaz Model Listeleme (Permission.DeviceModel.List)
     */
    getDeviceModels(requestParameters: GetDeviceModelsRequest): Promise<Array<DeviceModelDTO>>;

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * @summary Cihaz Model Ekleme (Permission.DeviceModel.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceModelPostDTO} [deviceModelPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    postDeviceModelRaw(requestParameters: PostDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModelDTO>>;

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Ekleme (Permission.DeviceModel.Add)
     */
    postDeviceModel(requestParameters: PostDeviceModelRequest): Promise<DeviceModelDTO>;

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * @summary Cihaz Model Güncelleme (Permission.DeviceModel.Edit)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {DeviceModelUpdateDTO} [deviceModelUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceModelApiInterface
     */
    putDeviceModelRaw(requestParameters: PutDeviceModelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Güncelleme (Permission.DeviceModel.Edit)
     */
    putDeviceModel(requestParameters: PutDeviceModelRequest): Promise<void>;

}

/**
 * 
 */
export class DeviceModelApi extends runtime.BaseAPI implements DeviceModelApiInterface {

    /**
     * Cihaz Model Silme (Permission.DeviceModel.Delete)
     */
    async deleteDeviceModelRaw(requestParameters: DeleteDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeviceModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceModelFromJSON(jsonValue));
    }

    /**
     * Cihaz Model Silme (Permission.DeviceModel.Delete)
     */
    async deleteDeviceModel(requestParameters: DeleteDeviceModelRequest): Promise<DeviceModel> {
        const response = await this.deleteDeviceModelRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cihaz Model Detay (Permission.DeviceModel.List)
     */
    async getDeviceModelRaw(requestParameters: GetDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModelDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeviceModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceModelDTOFromJSON(jsonValue));
    }

    /**
     * Cihaz Model Detay (Permission.DeviceModel.List)
     */
    async getDeviceModel(requestParameters: GetDeviceModelRequest): Promise<DeviceModelDTO> {
        const response = await this.getDeviceModelRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cihaz Model öneri Listeleme (Permission.DeviceModel.List)
     */
    async getDeviceModelSuggestionsRaw(requestParameters: GetDeviceModelSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     * Cihaz Model öneri Listeleme (Permission.DeviceModel.List)
     */
    async getDeviceModelSuggestions(requestParameters: GetDeviceModelSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getDeviceModelSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cihaz Model Listeleme (Permission.DeviceModel.List)
     */
    async getDeviceModelsRaw(requestParameters: GetDeviceModelsRequest): Promise<runtime.ApiResponse<Array<DeviceModelDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceModelDTOFromJSON));
    }

    /**
     * Cihaz Model Listeleme (Permission.DeviceModel.List)
     */
    async getDeviceModels(requestParameters: GetDeviceModelsRequest): Promise<Array<DeviceModelDTO>> {
        const response = await this.getDeviceModelsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Ekleme (Permission.DeviceModel.Add)
     */
    async postDeviceModelRaw(requestParameters: PostDeviceModelRequest): Promise<runtime.ApiResponse<DeviceModelDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceModelPostDTOToJSON(requestParameters.deviceModelPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceModelDTOFromJSON(jsonValue));
    }

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Ekleme (Permission.DeviceModel.Add)
     */
    async postDeviceModel(requestParameters: PostDeviceModelRequest): Promise<DeviceModelDTO> {
        const response = await this.postDeviceModelRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Güncelleme (Permission.DeviceModel.Edit)
     */
    async putDeviceModelRaw(requestParameters: PutDeviceModelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putDeviceModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/DeviceModel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceModelUpdateDTOToJSON(requestParameters.deviceModelUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tenant içerisinde cihaz model adları unique olmalı
     * Cihaz Model Güncelleme (Permission.DeviceModel.Edit)
     */
    async putDeviceModel(requestParameters: PutDeviceModelRequest): Promise<void> {
        await this.putDeviceModelRaw(requestParameters);
    }

}
