/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    DeviceGroupNameDTO,
    DeviceGroupNameDTOFromJSON,
    DeviceGroupNameDTOFromJSONTyped,
    DeviceGroupNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeviceUpdateDTO
 */
export interface DeviceUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceUpdateDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceUpdateDTO
     */
    description?: string | null;

    /**
     * Cihazın gerçek/fiziksel Id
     * @type {string}
     * @memberof DeviceUpdateDTO
     */
    registerId?: string | null;

    /**
     * Cihazın Iot.Data.Models.DeviceModel Id
     * @type {string}
     * @memberof DeviceUpdateDTO
     */
    deviceModelId?: string;

    /**
     * Cihazın bulunduğu grup listesi
     * @type {Array<DeviceGroupNameDTO>}
     * @memberof DeviceUpdateDTO
     */
    deviceGroups?: Array<DeviceGroupNameDTO> | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceUpdateDTO
     */
    id?: string;

}

export function DeviceUpdateDTOFromJSON(json: any): DeviceUpdateDTO {
    return DeviceUpdateDTOFromJSONTyped(json, false);
}

export function DeviceUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'registerId': !exists(json, 'registerId') ? undefined : json['registerId'],
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'deviceGroups': !exists(json, 'deviceGroups') ? undefined : (json['deviceGroups'] === null ? null : (json['deviceGroups'] as Array<any>).map(DeviceGroupNameDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DeviceUpdateDTOToJSON(value?: DeviceUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'registerId': value.registerId,
        'deviceModelId': value.deviceModelId,
        'deviceGroups': value.deviceGroups === undefined ? undefined : (value.deviceGroups === null ? null : (value.deviceGroups as Array<any>).map(DeviceGroupNameDTOToJSON)),
        'id': value.id,
    };
}


