/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleEngineAlarmConfiguration,
    RuleEngineAlarmConfigurationFromJSON,
    RuleEngineAlarmConfigurationFromJSONTyped,
    RuleEngineAlarmConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuleEngineAlarmUpdateDTO
 */
export interface RuleEngineAlarmUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    deviceId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    deviceGroupId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    deviceModelId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    name?: string | null;

    /**
     * 
     * @type {RuleEngineAlarmConfiguration}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    _configuration?: RuleEngineAlarmConfiguration;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmUpdateDTO
     */
    id: string;

}

export function RuleEngineAlarmUpdateDTOFromJSON(json: any): RuleEngineAlarmUpdateDTO {
    return RuleEngineAlarmUpdateDTOFromJSONTyped(json, false);
}

export function RuleEngineAlarmUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_configuration': !exists(json, 'configuration') ? undefined : RuleEngineAlarmConfigurationFromJSON(json['configuration']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': json['id'],
    };
}

export function RuleEngineAlarmUpdateDTOToJSON(value?: RuleEngineAlarmUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'deviceGroupId': value.deviceGroupId,
        'deviceModelId': value.deviceModelId,
        'name': value.name,
        'configuration': RuleEngineAlarmConfigurationToJSON(value._configuration),
        'description': value.description,
        'id': value.id,
    };
}


