/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleNodeDTO,
    RuleNodeDTOFromJSON,
    RuleNodeDTOFromJSONTyped,
    RuleNodeDTOToJSON,
    RuleNodeRelationDTO,
    RuleNodeRelationDTOFromJSON,
    RuleNodeRelationDTOFromJSONTyped,
    RuleNodeRelationDTOToJSON,
} from './';

/**
 * Kural listesi
 * @export
 * @interface RuleChainDTO
 */
export interface RuleChainDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleChainDTO
     */
    id: string;

    /**
     * 
     * @type {string}
     * @memberof RuleChainDTO
     */
    tenantName?: string | null;

    /**
     * 
     * @type {Date}
     * @memberof RuleChainDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof RuleChainDTO
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof RuleChainDTO
     */
    firstNodeId?: string;

    /**
     * 
     * @type {any}
     * @memberof RuleChainDTO
     */
    _configuration?: any | null;

    /**
     * 
     * @type {Array<RuleNodeDTO>}
     * @memberof RuleChainDTO
     */
    ruleNodes?: Array<RuleNodeDTO> | null;

    /**
     * 
     * @type {Array<RuleNodeRelationDTO>}
     * @memberof RuleChainDTO
     */
    ruleNodeRelations?: Array<RuleNodeRelationDTO> | null;

    /**
     * 
     * @type {boolean}
     * @memberof RuleChainDTO
     */
    rootChain?: boolean;

    /**
     * 
     * @type {string}
     * @memberof RuleChainDTO
     */
    tenantId?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleChainDTO
     */
    name?: string | null;

}

export function RuleChainDTOFromJSON(json: any): RuleChainDTO {
    return RuleChainDTOFromJSONTyped(json, false);
}

export function RuleChainDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleChainDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'firstNodeId': !exists(json, 'firstNodeId') ? undefined : json['firstNodeId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'ruleNodes': !exists(json, 'ruleNodes') ? undefined : (json['ruleNodes'] === null ? null : (json['ruleNodes'] as Array<any>).map(RuleNodeDTOFromJSON)),
        'ruleNodeRelations': !exists(json, 'ruleNodeRelations') ? undefined : (json['ruleNodeRelations'] === null ? null : (json['ruleNodeRelations'] as Array<any>).map(RuleNodeRelationDTOFromJSON)),
        'rootChain': !exists(json, 'rootChain') ? undefined : json['rootChain'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RuleChainDTOToJSON(value?: RuleChainDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantName': value.tenantName,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'firstNodeId': value.firstNodeId,
        'configuration': value._configuration,
        'ruleNodes': value.ruleNodes === undefined ? undefined : (value.ruleNodes === null ? null : (value.ruleNodes as Array<any>).map(RuleNodeDTOToJSON)),
        'ruleNodeRelations': value.ruleNodeRelations === undefined ? undefined : (value.ruleNodeRelations === null ? null : (value.ruleNodeRelations as Array<any>).map(RuleNodeRelationDTOToJSON)),
        'rootChain': value.rootChain,
        'tenantId': value.tenantId,
        'name': value.name,
    };
}


