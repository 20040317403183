/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Kural motoru içerisindeki işlem yapan node
 * @export
 * @interface RuleNodeDTO
 */
export interface RuleNodeDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeDTO
     */
    id: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeDTO
     */
    ruleChainName?: string | null;

    /**
     * 
     * @type {Date}
     * @memberof RuleNodeDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof RuleNodeDTO
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeDTO
     */
    ruleChainId?: string;

    /**
     * 
     * @type {any}
     * @memberof RuleNodeDTO
     */
    _configuration?: any | null;

    /**
     * Arayüz için Ek parametreler.
     * @type {any}
     * @memberof RuleNodeDTO
     */
    uiConfiguration?: any | null;

    /**
     * Görsel ismi
     * @type {string}
     * @memberof RuleNodeDTO
     */
    name?: string | null;

    /**
     * Node hangi class ile çalıştığını belirtir. Rule engine içersinde implementasyonu olmalıdır.
     * TODO: enum yapılabilir
     * @type {string}
     * @memberof RuleNodeDTO
     */
    type?: string | null;

}

export function RuleNodeDTOFromJSON(json: any): RuleNodeDTO {
    return RuleNodeDTOFromJSONTyped(json, false);
}

export function RuleNodeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNodeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ruleChainName': !exists(json, 'ruleChainName') ? undefined : json['ruleChainName'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'uiConfiguration': !exists(json, 'uiConfiguration') ? undefined : json['uiConfiguration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeDTOToJSON(value?: RuleNodeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ruleChainName': value.ruleChainName,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'ruleChainId': value.ruleChainId,
        'configuration': value._configuration,
        'uiConfiguration': value.uiConfiguration,
        'name': value.name,
        'type': value.type,
    };
}


