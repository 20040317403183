/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    CommandToDeviceLogDTO,
    CommandToDeviceLogDTOFromJSON,
    CommandToDeviceLogDTOToJSON,
    CommandToDeviceLogDTOListPagedResponse,
    CommandToDeviceLogDTOListPagedResponseFromJSON,
    CommandToDeviceLogDTOListPagedResponseToJSON,
    CommandToDeviceLogStatus,
    CommandToDeviceLogStatusFromJSON,
    CommandToDeviceLogStatusToJSON,
    CommandToDevicePostDTO,
    CommandToDevicePostDTOFromJSON,
    CommandToDevicePostDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetCommandToDeviceLogRequest {
    deviceId?: string;
    status?: CommandToDeviceLogStatus;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    pageNumber?: number;
    pageSize?: number;
    xTenantId?: string;
}

export interface PostCommandTodeviceRequest {
    xTenantId?: string;
    commandToDevicePostDTO?: CommandToDevicePostDTO;
}

/**
 * CommandApi - interface
 * 
 * @export
 * @interface CommandApiInterface
 */
export interface CommandApiInterface {
    /**
     * Cihaz, status ve tarihlere göre filtreleyerek CommandToDeviceLog verilerini listeler.
     * @summary Cihaz Komut Log  listeleme.
     * @param {string} [deviceId] 
     * @param {CommandToDeviceLogStatus} [status] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApiInterface
     */
    getCommandToDeviceLogRaw(requestParameters: GetCommandToDeviceLogRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTOListPagedResponse>>;

    /**
     * Cihaz, status ve tarihlere göre filtreleyerek CommandToDeviceLog verilerini listeler.
     * Cihaz Komut Log  listeleme.
     */
    getCommandToDeviceLog(requestParameters: GetCommandToDeviceLogRequest): Promise<CommandToDeviceLogDTOListPagedResponse>;

    /**
     * Gönderilen komut içerisinde \"Sender\" kısmında gönderen bilgisini belirtilmeli.  Bios cihazları için son konum isteği:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  }  bios cihazlar için ip/port ayarı:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  \"params\": {  \"ip\":\"10.1.1.1.1\",  \"port\":\"5050\"  }  }
     * @summary Cihaza Komut gönderme
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {CommandToDevicePostDTO} [commandToDevicePostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApiInterface
     */
    postCommandTodeviceRaw(requestParameters: PostCommandTodeviceRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTO>>;

    /**
     * Gönderilen komut içerisinde \"Sender\" kısmında gönderen bilgisini belirtilmeli.  Bios cihazları için son konum isteği:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  }  bios cihazlar için ip/port ayarı:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  \"params\": {  \"ip\":\"10.1.1.1.1\",  \"port\":\"5050\"  }  }
     * Cihaza Komut gönderme
     */
    postCommandTodevice(requestParameters: PostCommandTodeviceRequest): Promise<CommandToDeviceLogDTO>;

}

/**
 * 
 */
export class CommandApi extends runtime.BaseAPI implements CommandApiInterface {

    /**
     * Cihaz, status ve tarihlere göre filtreleyerek CommandToDeviceLog verilerini listeler.
     * Cihaz Komut Log  listeleme.
     */
    async getCommandToDeviceLogRaw(requestParameters: GetCommandToDeviceLogRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTOListPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Command/GetCommandToDeviceLog`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandToDeviceLogDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     * Cihaz, status ve tarihlere göre filtreleyerek CommandToDeviceLog verilerini listeler.
     * Cihaz Komut Log  listeleme.
     */
    async getCommandToDeviceLog(requestParameters: GetCommandToDeviceLogRequest): Promise<CommandToDeviceLogDTOListPagedResponse> {
        const response = await this.getCommandToDeviceLogRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gönderilen komut içerisinde \"Sender\" kısmında gönderen bilgisini belirtilmeli.  Bios cihazları için son konum isteği:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  }  bios cihazlar için ip/port ayarı:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  \"params\": {  \"ip\":\"10.1.1.1.1\",  \"port\":\"5050\"  }  }
     * Cihaza Komut gönderme
     */
    async postCommandTodeviceRaw(requestParameters: PostCommandTodeviceRequest): Promise<runtime.ApiResponse<CommandToDeviceLogDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Command`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommandToDevicePostDTOToJSON(requestParameters.commandToDevicePostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommandToDeviceLogDTOFromJSON(jsonValue));
    }

    /**
     * Gönderilen komut içerisinde \"Sender\" kısmında gönderen bilgisini belirtilmeli.  Bios cihazları için son konum isteği:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  }  bios cihazlar için ip/port ayarı:  {  \"sender\": \"Api test client\",  \"timestamp\": \"2021-08-25T13:57:23.687Z\",  \"deviceId\": \"3fa85f64-5717-4562-b3fc-2c963f66afa6\",  \"command\": \"SK\",  \"params\": {  \"ip\":\"10.1.1.1.1\",  \"port\":\"5050\"  }  }
     * Cihaza Komut gönderme
     */
    async postCommandTodevice(requestParameters: PostCommandTodeviceRequest): Promise<CommandToDeviceLogDTO> {
        const response = await this.postCommandTodeviceRaw(requestParameters);
        return await response.value();
    }

}
