/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gatewayların kullanabileceği mesaj protocolleri
 * @export
 * @enum {string}
 */
export enum ConnectionProtocols {
    Tcp = 'tcp',
    Udp = 'udp',
    Mqtt = 'mqtt',
    Sms = 'sms',
    Http = 'http',
    TcpGateway = 'tcpGateway'
}

export function ConnectionProtocolsFromJSON(json: any): ConnectionProtocols {
    return ConnectionProtocolsFromJSONTyped(json, false);
}

export function ConnectionProtocolsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionProtocols {
    return json as ConnectionProtocols;
}

export function ConnectionProtocolsToJSON(value?: ConnectionProtocols | null): any {
    return value as any;
}

