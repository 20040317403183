/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    userName: string;

    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password: string;

}

export function LoginDtoFromJSON(json: any): LoginDto {
    return LoginDtoFromJSONTyped(json, false);
}

export function LoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'password': json['password'],
    };
}

export function LoginDtoToJSON(value?: LoginDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'password': value.password,
    };
}


