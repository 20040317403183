import { ChangeEvent } from "react";
import { TenantDTO } from "../api";
import DataStore from "./DataStore";
import RootStore from "./RootStore"

class UserStore {
    rootStore: RootStore

    ImpersonationTenantId: string | null = null
    tenant: TenantDTO | null = null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    handleImpersonationChange = (tenant: TenantDTO | null) => {
        this.ImpersonationTenantId = tenant?.id == undefined ? null : tenant.id;

        this.rootStore.dataStore = new DataStore(this.rootStore)
        this.rootStore.routing.goTo("force", { return: this.rootStore.routing.getCurrentRoute()!.name });
    };
}

export default UserStore