/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    RuleChainDTO,
    RuleChainDTOFromJSON,
    RuleChainDTOToJSON,
    RuleChainPostDTO,
    RuleChainPostDTOFromJSON,
    RuleChainPostDTOToJSON,
    RuleChainUpdateDTO,
    RuleChainUpdateDTOFromJSON,
    RuleChainUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetRuleChainRequest {
    id: string;
    xTenantId?: string;
}

export interface GetRuleChainSensorTypesRequest {
    id: string;
    xTenantId?: string;
}

export interface GetRuleChainSuggestionsRequest {
    xTenantId?: string;
}

export interface GetRuleChainsRequest {
    xTenantId?: string;
}

export interface PostRuleChainRequest {
    xTenantId?: string;
    ruleChainPostDTO?: RuleChainPostDTO;
}

export interface PutRuleChainRulesRequest {
    xTenantId?: string;
    ruleChainUpdateDTO?: RuleChainUpdateDTO;
}

/**
 * RuleChainApi - interface
 * 
 * @export
 * @interface RuleChainApiInterface
 */
export interface RuleChainApiInterface {
    /**
     * 
     * @summary RuleChain detayları (Permission.RuleChain.List)
     * @param {string} id RuleChain id
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    getRuleChainRaw(requestParameters: GetRuleChainRequest): Promise<runtime.ApiResponse<RuleChainDTO>>;

    /**
     * RuleChain detayları (Permission.RuleChain.List)
     */
    getRuleChain(requestParameters: GetRuleChainRequest): Promise<RuleChainDTO>;

    /**
     * Son gönderilen mesajlardaki sensör tiplerini listeler
     * @summary Rulechain sensör tipleri listeleme (Permission.RuleChain.List)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    getRuleChainSensorTypesRaw(requestParameters: GetRuleChainSensorTypesRequest): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * Son gönderilen mesajlardaki sensör tiplerini listeler
     * Rulechain sensör tipleri listeleme (Permission.RuleChain.List)
     */
    getRuleChainSensorTypes(requestParameters: GetRuleChainSensorTypesRequest): Promise<Array<string>>;

    /**
     * 
     * @summary RuleChain Öneri Listeleme (Permission.RuleChain.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    getRuleChainSuggestionsRaw(requestParameters: GetRuleChainSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     * RuleChain Öneri Listeleme (Permission.RuleChain.List)
     */
    getRuleChainSuggestions(requestParameters: GetRuleChainSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @summary RuleChain Listeleme (Permission.RuleChain.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    getRuleChainsRaw(requestParameters: GetRuleChainsRequest): Promise<runtime.ApiResponse<Array<RuleChainDTO>>>;

    /**
     * RuleChain Listeleme (Permission.RuleChain.List)
     */
    getRuleChains(requestParameters: GetRuleChainsRequest): Promise<Array<RuleChainDTO>>;

    /**
     * 
     * @summary RuleChain ekleme
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RuleChainPostDTO} [ruleChainPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    postRuleChainRaw(requestParameters: PostRuleChainRequest): Promise<runtime.ApiResponse<RuleChainDTO>>;

    /**
     * RuleChain ekleme
     */
    postRuleChain(requestParameters: PostRuleChainRequest): Promise<RuleChainDTO>;

    /**
     * 
     * @summary RuleChain kural güncelleme (Permission.RuleChain.Edit)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RuleChainUpdateDTO} [ruleChainUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleChainApiInterface
     */
    putRuleChainRulesRaw(requestParameters: PutRuleChainRulesRequest): Promise<runtime.ApiResponse<RuleChainUpdateDTO>>;

    /**
     * RuleChain kural güncelleme (Permission.RuleChain.Edit)
     */
    putRuleChainRules(requestParameters: PutRuleChainRulesRequest): Promise<RuleChainUpdateDTO>;

}

/**
 * 
 */
export class RuleChainApi extends runtime.BaseAPI implements RuleChainApiInterface {

    /**
     * RuleChain detayları (Permission.RuleChain.List)
     */
    async getRuleChainRaw(requestParameters: GetRuleChainRequest): Promise<runtime.ApiResponse<RuleChainDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRuleChain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleChainDTOFromJSON(jsonValue));
    }

    /**
     * RuleChain detayları (Permission.RuleChain.List)
     */
    async getRuleChain(requestParameters: GetRuleChainRequest): Promise<RuleChainDTO> {
        const response = await this.getRuleChainRaw(requestParameters);
        return await response.value();
    }

    /**
     * Son gönderilen mesajlardaki sensör tiplerini listeler
     * Rulechain sensör tipleri listeleme (Permission.RuleChain.List)
     */
    async getRuleChainSensorTypesRaw(requestParameters: GetRuleChainSensorTypesRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRuleChainSensorTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain/{id}/sensorTypes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Son gönderilen mesajlardaki sensör tiplerini listeler
     * Rulechain sensör tipleri listeleme (Permission.RuleChain.List)
     */
    async getRuleChainSensorTypes(requestParameters: GetRuleChainSensorTypesRequest): Promise<Array<string>> {
        const response = await this.getRuleChainSensorTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleChain Öneri Listeleme (Permission.RuleChain.List)
     */
    async getRuleChainSuggestionsRaw(requestParameters: GetRuleChainSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     * RuleChain Öneri Listeleme (Permission.RuleChain.List)
     */
    async getRuleChainSuggestions(requestParameters: GetRuleChainSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getRuleChainSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleChain Listeleme (Permission.RuleChain.List)
     */
    async getRuleChainsRaw(requestParameters: GetRuleChainsRequest): Promise<runtime.ApiResponse<Array<RuleChainDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleChainDTOFromJSON));
    }

    /**
     * RuleChain Listeleme (Permission.RuleChain.List)
     */
    async getRuleChains(requestParameters: GetRuleChainsRequest): Promise<Array<RuleChainDTO>> {
        const response = await this.getRuleChainsRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleChain ekleme
     */
    async postRuleChainRaw(requestParameters: PostRuleChainRequest): Promise<runtime.ApiResponse<RuleChainDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleChainPostDTOToJSON(requestParameters.ruleChainPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleChainDTOFromJSON(jsonValue));
    }

    /**
     * RuleChain ekleme
     */
    async postRuleChain(requestParameters: PostRuleChainRequest): Promise<RuleChainDTO> {
        const response = await this.postRuleChainRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleChain kural güncelleme (Permission.RuleChain.Edit)
     */
    async putRuleChainRulesRaw(requestParameters: PutRuleChainRulesRequest): Promise<runtime.ApiResponse<RuleChainUpdateDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/RuleChain/rules`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleChainUpdateDTOToJSON(requestParameters.ruleChainUpdateDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleChainUpdateDTOFromJSON(jsonValue));
    }

    /**
     * RuleChain kural güncelleme (Permission.RuleChain.Edit)
     */
    async putRuleChainRules(requestParameters: PutRuleChainRulesRequest): Promise<RuleChainUpdateDTO> {
        const response = await this.putRuleChainRulesRaw(requestParameters);
        return await response.value();
    }

}
