/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    TimeSeriesDataDTO,
    TimeSeriesDataDTOFromJSON,
    TimeSeriesDataDTOToJSON,
    TimeSeriesDataDTOListPagedResponse,
    TimeSeriesDataDTOListPagedResponseFromJSON,
    TimeSeriesDataDTOListPagedResponseToJSON,
} from '../models';

export interface GetTimeSeriesDataRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    deviceId?: string;
    typeKeys?: Array<string>;
    deviceModelId?: string;
    pageNumber?: number;
    pageSize?: number;
    xTenantId?: string;
}

export interface PostTimeSeriesDataRequest {
    xTenantId?: string;
    timeSeriesDataDTO?: TimeSeriesDataDTO;
}

/**
 * TimeSeriesDataApi - interface
 * 
 * @export
 * @interface TimeSeriesDataApiInterface
 */
export interface TimeSeriesDataApiInterface {
    /**
     * Tenant, cihaz, sensortype, cihaz model ve tarihlere göre filtreleyerek TimeSeriesData verilerini listeler.
     * @summary TimeSeriesData listeleme (Permission.TimeSeriesData.List)
     * @param {Date} [startDate] Sorgu başlangıç tarihi
     * @param {Date} [endDate] Sorgu bitiş tarihi
     * @param {string} [deviceId] opsiyonel cihaz filtresi
     * @param {Array<string>} [typeKeys] opsiyonel timeseries veri filtresi
     * @param {string} [deviceModelId] opsiyonel cihaz model filtresi
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSeriesDataApiInterface
     */
    getTimeSeriesDataRaw(requestParameters: GetTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>>;

    /**
     * Tenant, cihaz, sensortype, cihaz model ve tarihlere göre filtreleyerek TimeSeriesData verilerini listeler.
     * TimeSeriesData listeleme (Permission.TimeSeriesData.List)
     */
    getTimeSeriesData(requestParameters: GetTimeSeriesDataRequest): Promise<TimeSeriesDataDTOListPagedResponse>;

    /**
     * Gelen TimeSeriesData verisini TimeSeriesData tablosuna ekler. TimeSeriesDataLatest tablosunu günceller.
     * @summary TimeSeriesData ekleme (Permission.TimeSeriesData.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {TimeSeriesDataDTO} [timeSeriesDataDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSeriesDataApiInterface
     */
    postTimeSeriesDataRaw(requestParameters: PostTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTO>>;

    /**
     * Gelen TimeSeriesData verisini TimeSeriesData tablosuna ekler. TimeSeriesDataLatest tablosunu günceller.
     * TimeSeriesData ekleme (Permission.TimeSeriesData.Add)
     */
    postTimeSeriesData(requestParameters: PostTimeSeriesDataRequest): Promise<TimeSeriesDataDTO>;

}

/**
 * 
 */
export class TimeSeriesDataApi extends runtime.BaseAPI implements TimeSeriesDataApiInterface {

    /**
     * Tenant, cihaz, sensortype, cihaz model ve tarihlere göre filtreleyerek TimeSeriesData verilerini listeler.
     * TimeSeriesData listeleme (Permission.TimeSeriesData.List)
     */
    async getTimeSeriesDataRaw(requestParameters: GetTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTOListPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.typeKeys) {
            queryParameters['typeKeys'] = requestParameters.typeKeys;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/TimeSeriesData/GetTimeSeriesData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesDataDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     * Tenant, cihaz, sensortype, cihaz model ve tarihlere göre filtreleyerek TimeSeriesData verilerini listeler.
     * TimeSeriesData listeleme (Permission.TimeSeriesData.List)
     */
    async getTimeSeriesData(requestParameters: GetTimeSeriesDataRequest): Promise<TimeSeriesDataDTOListPagedResponse> {
        const response = await this.getTimeSeriesDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gelen TimeSeriesData verisini TimeSeriesData tablosuna ekler. TimeSeriesDataLatest tablosunu günceller.
     * TimeSeriesData ekleme (Permission.TimeSeriesData.Add)
     */
    async postTimeSeriesDataRaw(requestParameters: PostTimeSeriesDataRequest): Promise<runtime.ApiResponse<TimeSeriesDataDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/TimeSeriesData/PostTimeSeriesData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeSeriesDataDTOToJSON(requestParameters.timeSeriesDataDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesDataDTOFromJSON(jsonValue));
    }

    /**
     * Gelen TimeSeriesData verisini TimeSeriesData tablosuna ekler. TimeSeriesDataLatest tablosunu günceller.
     * TimeSeriesData ekleme (Permission.TimeSeriesData.Add)
     */
    async postTimeSeriesData(requestParameters: PostTimeSeriesDataRequest): Promise<TimeSeriesDataDTO> {
        const response = await this.postTimeSeriesDataRaw(requestParameters);
        return await response.value();
    }

}
