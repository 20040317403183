/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    ClaimsIdentity,
    ClaimsIdentityFromJSON,
    ClaimsIdentityFromJSONTyped,
    ClaimsIdentityToJSON,
} from './';

/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    issuer?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    originalIssuer?: string | null;

    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Claim
     */
    readonly properties?: { [key: string]: string; } | null;

    /**
     * 
     * @type {ClaimsIdentity}
     * @memberof Claim
     */
    subject?: ClaimsIdentity;

    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    type?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    value?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    valueType?: string | null;

}

export function ClaimFromJSON(json: any): Claim {
    return ClaimFromJSONTyped(json, false);
}

export function ClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): Claim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
        'originalIssuer': !exists(json, 'originalIssuer') ? undefined : json['originalIssuer'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'subject': !exists(json, 'subject') ? undefined : ClaimsIdentityFromJSON(json['subject']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueType': !exists(json, 'valueType') ? undefined : json['valueType'],
    };
}

export function ClaimToJSON(value?: Claim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issuer': value.issuer,
        'originalIssuer': value.originalIssuer,
        'subject': ClaimsIdentityToJSON(value.subject),
        'type': value.type,
        'value': value.value,
        'valueType': value.valueType,
    };
}


