/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleNodeRelationUpdateDTO,
    RuleNodeRelationUpdateDTOFromJSON,
    RuleNodeRelationUpdateDTOFromJSONTyped,
    RuleNodeRelationUpdateDTOToJSON,
    RuleNodeUpdateDTO,
    RuleNodeUpdateDTOFromJSON,
    RuleNodeUpdateDTOFromJSONTyped,
    RuleNodeUpdateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuleChainUpdateDTO
 */
export interface RuleChainUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleChainUpdateDTO
     */
    id: string;

    /**
     * 
     * @type {string}
     * @memberof RuleChainUpdateDTO
     */
    firstNodeId?: string;

    /**
     * 
     * @type {any}
     * @memberof RuleChainUpdateDTO
     */
    _configuration?: any | null;

    /**
     * 
     * @type {Array<RuleNodeUpdateDTO>}
     * @memberof RuleChainUpdateDTO
     */
    ruleNodes?: Array<RuleNodeUpdateDTO> | null;

    /**
     * 
     * @type {Array<RuleNodeRelationUpdateDTO>}
     * @memberof RuleChainUpdateDTO
     */
    ruleNodeRelations?: Array<RuleNodeRelationUpdateDTO> | null;

    /**
     * 
     * @type {boolean}
     * @memberof RuleChainUpdateDTO
     */
    rootChain?: boolean;

    /**
     * 
     * @type {string}
     * @memberof RuleChainUpdateDTO
     */
    name?: string | null;

}

export function RuleChainUpdateDTOFromJSON(json: any): RuleChainUpdateDTO {
    return RuleChainUpdateDTOFromJSONTyped(json, false);
}

export function RuleChainUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleChainUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstNodeId': !exists(json, 'firstNodeId') ? undefined : json['firstNodeId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'ruleNodes': !exists(json, 'ruleNodes') ? undefined : (json['ruleNodes'] === null ? null : (json['ruleNodes'] as Array<any>).map(RuleNodeUpdateDTOFromJSON)),
        'ruleNodeRelations': !exists(json, 'ruleNodeRelations') ? undefined : (json['ruleNodeRelations'] === null ? null : (json['ruleNodeRelations'] as Array<any>).map(RuleNodeRelationUpdateDTOFromJSON)),
        'rootChain': !exists(json, 'rootChain') ? undefined : json['rootChain'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RuleChainUpdateDTOToJSON(value?: RuleChainUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstNodeId': value.firstNodeId,
        'configuration': value._configuration,
        'ruleNodes': value.ruleNodes === undefined ? undefined : (value.ruleNodes === null ? null : (value.ruleNodes as Array<any>).map(RuleNodeUpdateDTOToJSON)),
        'ruleNodeRelations': value.ruleNodeRelations === undefined ? undefined : (value.ruleNodeRelations === null ? null : (value.ruleNodeRelations as Array<any>).map(RuleNodeRelationUpdateDTOToJSON)),
        'rootChain': value.rootChain,
        'name': value.name,
    };
}


