/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Cihaz grupları
 * @export
 * @interface DeviceGroupDTO
 */
export interface DeviceGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    parentId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    id?: string;

    /**
     * 
     * @type {Date}
     * @memberof DeviceGroupDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceGroupDTO
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    tenantName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    tenantId?: string;

    /**
     * 
     * @type {string}
     * @memberof DeviceGroupDTO
     */
    parentName?: string | null;

}

export function DeviceGroupDTOFromJSON(json: any): DeviceGroupDTO {
    return DeviceGroupDTOFromJSONTyped(json, false);
}

export function DeviceGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceGroupDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
    };
}

export function DeviceGroupDTOToJSON(value?: DeviceGroupDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'parentId': value.parentId,
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'tenantName': value.tenantName,
        'tenantId': value.tenantId,
        'parentName': value.parentName,
    };
}


