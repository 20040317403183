/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * 
 * @export
 * @interface TimeSeriesDataLatest
 */
export interface TimeSeriesDataLatest {
    /**
     * 
     * @type {Tenant}
     * @memberof TimeSeriesDataLatest
     */
    tenant?: Tenant;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataLatest
     */
    deviceId?: string;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataLatest
     */
    typeKey?: string | null;

    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesDataLatest
     */
    timestamp?: moment.Moment;

    /**
     * 
     * @type {boolean}
     * @memberof TimeSeriesDataLatest
     */
    bool?: boolean | null;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataLatest
     */
    str?: string | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataLatest
     */
    _long?: number | null;

    /**
     * 
     * @type {number}
     * @memberof TimeSeriesDataLatest
     */
    _float?: number | null;

    /**
     * 
     * @type {any}
     * @memberof TimeSeriesDataLatest
     */
    json?: any | null;

    /**
     * 
     * @type {object}
     * @memberof TimeSeriesDataLatest
     */
    location?: object | null;

    /**
     * 
     * @type {string}
     * @memberof TimeSeriesDataLatest
     */
    tenantId?: string;

}

export function TimeSeriesDataLatestFromJSON(json: any): TimeSeriesDataLatest {
    return TimeSeriesDataLatestFromJSONTyped(json, false);
}

export function TimeSeriesDataLatestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesDataLatest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (moment(json['timestamp'])),

        'bool': !exists(json, 'bool') ? undefined : json['bool'],
        'str': !exists(json, 'str') ? undefined : json['str'],
        '_long': !exists(json, 'long') ? undefined : json['long'],
        '_float': !exists(json, 'float') ? undefined : json['float'],
        'json': !exists(json, 'json') ? undefined : json['json'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function TimeSeriesDataLatestToJSON(value?: TimeSeriesDataLatest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenant': TenantToJSON(value.tenant),
        'deviceId': value.deviceId,
        'typeKey': value.typeKey,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'bool': value.bool,
        'str': value.str,
        'long': value._long,
        'float': value._float,
        'json': value.json,
        'location': value.location,
        'tenantId': value.tenantId,
    };
}


