/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    AlarmEventDTOListPagedResponse,
    AlarmEventDTOListPagedResponseFromJSON,
    AlarmEventDTOListPagedResponseToJSON,
    DeviceEventDTOListPagedResponse,
    DeviceEventDTOListPagedResponseFromJSON,
    DeviceEventDTOListPagedResponseToJSON,
    RuleEngineAlarm,
    RuleEngineAlarmFromJSON,
    RuleEngineAlarmToJSON,
    RuleEngineAlarmDTO,
    RuleEngineAlarmDTOFromJSON,
    RuleEngineAlarmDTOToJSON,
    RuleEngineAlarmPostDTO,
    RuleEngineAlarmPostDTOFromJSON,
    RuleEngineAlarmPostDTOToJSON,
    RuleEngineAlarmUpdateDTO,
    RuleEngineAlarmUpdateDTOFromJSON,
    RuleEngineAlarmUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteRuleEngineAlarmRequest {
    id: string;
    xTenantId?: string;
}

export interface GetAlarmCountRequest {
    xTenantId?: string;
}

export interface GetAlarmEventCountRequest {
    xTenantId?: string;
}

export interface GetAlarmEventsRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    deviceId?: string;
    typeKeys?: Array<string>;
    deviceModelId?: string;
    pageNumber?: number;
    pageSize?: number;
    xTenantId?: string;
}

export interface GetDeviceEventCountRequest {
    xTenantId?: string;
}

export interface GetDeviceEventsRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    deviceId?: string;
    typeKeys?: Array<string>;
    deviceModelId?: string;
    pageNumber?: number;
    pageSize?: number;
    xTenantId?: string;
}

export interface GetRuleEngineAlarmRequest {
    id: string;
    xTenantId?: string;
}

export interface GetRuleEngineAlarmSuggestionsRequest {
    deviceModelId?: string;
    xTenantId?: string;
}

export interface GetRuleEngineAlarmsRequest {
    deviceModelId?: string;
    xTenantId?: string;
}

export interface GetTotalAlarmCountRequest {
    xTenantId?: string;
}

export interface GetTotalAlarmEventCountRequest {
    xTenantId?: string;
}

export interface GetTotalDeviceEventCountRequest {
    xTenantId?: string;
}

export interface PostRuleEngineAlarmRequest {
    xTenantId?: string;
    ruleEngineAlarmPostDTO?: RuleEngineAlarmPostDTO;
}

export interface PutRuleEngineAlarmRequest {
    id: string;
    xTenantId?: string;
    ruleEngineAlarmUpdateDTO?: RuleEngineAlarmUpdateDTO;
}

/**
 * AlarmApi - interface
 * 
 * @export
 * @interface AlarmApiInterface
 */
export interface AlarmApiInterface {
    /**
     * 
     * @summary Alarm  Silme (Permission.RuleEngineAlarm.Delete)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    deleteRuleEngineAlarmRaw(requestParameters: DeleteRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarm>>;

    /**
     * Alarm  Silme (Permission.RuleEngineAlarm.Delete)
     */
    deleteRuleEngineAlarm(requestParameters: DeleteRuleEngineAlarmRequest): Promise<RuleEngineAlarm>;

    /**
     * 
     * @summary Rule Engine Alarm Sayısı (Permission.RuleEngineAlarm.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getAlarmCountRaw(requestParameters: GetAlarmCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Rule Engine Alarm Sayısı (Permission.RuleEngineAlarm.List)
     */
    getAlarmCount(requestParameters: GetAlarmCountRequest): Promise<number>;

    /**
     * 
     * @summary Alarm Event Sayısı (Permission.AlarmEvent.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getAlarmEventCountRaw(requestParameters: GetAlarmEventCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    getAlarmEventCount(requestParameters: GetAlarmEventCountRequest): Promise<number>;

    /**
     * 
     * @summary Alarm Event Listeleme (Permission.AlarmEvent.List)
     * @param {Date} [startDate] Sorgu başlangıç tarihi
     * @param {Date} [endDate] Sorgu bitiş tarihi
     * @param {string} [deviceId] opsiyonel cihaz filtresi
     * @param {Array<string>} [typeKeys] opsiyonel timeseries veri filtresi
     * @param {string} [deviceModelId] opsiyonel cihaz model filtresi
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getAlarmEventsRaw(requestParameters: GetAlarmEventsRequest): Promise<runtime.ApiResponse<AlarmEventDTOListPagedResponse>>;

    /**
     * Alarm Event Listeleme (Permission.AlarmEvent.List)
     */
    getAlarmEvents(requestParameters: GetAlarmEventsRequest): Promise<AlarmEventDTOListPagedResponse>;

    /**
     * 
     * @summary Device Event Sayısı (Permission.DeviceEvent.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getDeviceEventCountRaw(requestParameters: GetDeviceEventCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Device Event Sayısı (Permission.DeviceEvent.List)
     */
    getDeviceEventCount(requestParameters: GetDeviceEventCountRequest): Promise<number>;

    /**
     * 
     * @summary Device Event Listeleme (Permission.DeviceEvent.List)
     * @param {Date} [startDate] Sorgu başlangıç tarihi
     * @param {Date} [endDate] Sorgu bitiş tarihi
     * @param {string} [deviceId] opsiyonel cihaz filtresi
     * @param {Array<string>} [typeKeys] opsiyonel timeseries veri filtresi
     * @param {string} [deviceModelId] opsiyonel cihaz model filtresi
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getDeviceEventsRaw(requestParameters: GetDeviceEventsRequest): Promise<runtime.ApiResponse<DeviceEventDTOListPagedResponse>>;

    /**
     * Device Event Listeleme (Permission.DeviceEvent.List)
     */
    getDeviceEvents(requestParameters: GetDeviceEventsRequest): Promise<DeviceEventDTOListPagedResponse>;

    /**
     * 
     * @summary RuleEngineAlarm detayları (Permission.RuleEngineAlarm.List)
     * @param {string} id RuleEngineAlarm id
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getRuleEngineAlarmRaw(requestParameters: GetRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>>;

    /**
     * RuleEngineAlarm detayları (Permission.RuleEngineAlarm.List)
     */
    getRuleEngineAlarm(requestParameters: GetRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO>;

    /**
     * 
     * @summary RuleEngineAlarm Öneri Listeleme (Permission.RuleEngineAlarm.List)
     * @param {string} [deviceModelId] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getRuleEngineAlarmSuggestionsRaw(requestParameters: GetRuleEngineAlarmSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     * RuleEngineAlarm Öneri Listeleme (Permission.RuleEngineAlarm.List)
     */
    getRuleEngineAlarmSuggestions(requestParameters: GetRuleEngineAlarmSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @summary RuleEngineAlarm Listeleme (Permission.RuleEngineAlarm.List)
     * @param {string} [deviceModelId] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getRuleEngineAlarmsRaw(requestParameters: GetRuleEngineAlarmsRequest): Promise<runtime.ApiResponse<Array<RuleEngineAlarmDTO>>>;

    /**
     * RuleEngineAlarm Listeleme (Permission.RuleEngineAlarm.List)
     */
    getRuleEngineAlarms(requestParameters: GetRuleEngineAlarmsRequest): Promise<Array<RuleEngineAlarmDTO>>;

    /**
     * 
     * @summary Toplam Rule Engine Alarm Sayısı  (Permission.RuleEngineAlarm.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getTotalAlarmCountRaw(requestParameters: GetTotalAlarmCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Toplam Rule Engine Alarm Sayısı  (Permission.RuleEngineAlarm.List)
     */
    getTotalAlarmCount(requestParameters: GetTotalAlarmCountRequest): Promise<number>;

    /**
     * 
     * @summary Toplam Alarm Event Sayısı (Permission.AlarmEvent.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getTotalAlarmEventCountRaw(requestParameters: GetTotalAlarmEventCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Toplam Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    getTotalAlarmEventCount(requestParameters: GetTotalAlarmEventCountRequest): Promise<number>;

    /**
     * 
     * @summary Toplam  Device Event Sayısı (Permission.DeviceEvent.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    getTotalDeviceEventCountRaw(requestParameters: GetTotalDeviceEventCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Toplam  Device Event Sayısı (Permission.DeviceEvent.List)
     */
    getTotalDeviceEventCount(requestParameters: GetTotalDeviceEventCountRequest): Promise<number>;

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * @summary RuleEngineAlarm Ekleme (Permission.RuleEngineAlarm.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RuleEngineAlarmPostDTO} [ruleEngineAlarmPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    postRuleEngineAlarmRaw(requestParameters: PostRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>>;

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm Ekleme (Permission.RuleEngineAlarm.Add)
     */
    postRuleEngineAlarm(requestParameters: PostRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO>;

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * @summary RuleEngineAlarm güncelleme (Permission.RuleEngineAlarm.Edit)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {RuleEngineAlarmUpdateDTO} [ruleEngineAlarmUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmApiInterface
     */
    putRuleEngineAlarmRaw(requestParameters: PutRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>>;

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm güncelleme (Permission.RuleEngineAlarm.Edit)
     */
    putRuleEngineAlarm(requestParameters: PutRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO>;

}

/**
 * 
 */
export class AlarmApi extends runtime.BaseAPI implements AlarmApiInterface {

    /**
     * Alarm  Silme (Permission.RuleEngineAlarm.Delete)
     */
    async deleteRuleEngineAlarmRaw(requestParameters: DeleteRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRuleEngineAlarm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleEngineAlarmFromJSON(jsonValue));
    }

    /**
     * Alarm  Silme (Permission.RuleEngineAlarm.Delete)
     */
    async deleteRuleEngineAlarm(requestParameters: DeleteRuleEngineAlarmRequest): Promise<RuleEngineAlarm> {
        const response = await this.deleteRuleEngineAlarmRaw(requestParameters);
        return await response.value();
    }

    /**
     * Rule Engine Alarm Sayısı (Permission.RuleEngineAlarm.List)
     */
    async getAlarmCountRaw(requestParameters: GetAlarmCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/alarmCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Rule Engine Alarm Sayısı (Permission.RuleEngineAlarm.List)
     */
    async getAlarmCount(requestParameters: GetAlarmCountRequest): Promise<number> {
        const response = await this.getAlarmCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    async getAlarmEventCountRaw(requestParameters: GetAlarmEventCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/alarmEventCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    async getAlarmEventCount(requestParameters: GetAlarmEventCountRequest): Promise<number> {
        const response = await this.getAlarmEventCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Alarm Event Listeleme (Permission.AlarmEvent.List)
     */
    async getAlarmEventsRaw(requestParameters: GetAlarmEventsRequest): Promise<runtime.ApiResponse<AlarmEventDTOListPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.typeKeys) {
            queryParameters['typeKeys'] = requestParameters.typeKeys;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/alarmEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AlarmEventDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     * Alarm Event Listeleme (Permission.AlarmEvent.List)
     */
    async getAlarmEvents(requestParameters: GetAlarmEventsRequest): Promise<AlarmEventDTOListPagedResponse> {
        const response = await this.getAlarmEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Device Event Sayısı (Permission.DeviceEvent.List)
     */
    async getDeviceEventCountRaw(requestParameters: GetDeviceEventCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/deviceEventCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Device Event Sayısı (Permission.DeviceEvent.List)
     */
    async getDeviceEventCount(requestParameters: GetDeviceEventCountRequest): Promise<number> {
        const response = await this.getDeviceEventCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Device Event Listeleme (Permission.DeviceEvent.List)
     */
    async getDeviceEventsRaw(requestParameters: GetDeviceEventsRequest): Promise<runtime.ApiResponse<DeviceEventDTOListPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        if (requestParameters.typeKeys) {
            queryParameters['typeKeys'] = requestParameters.typeKeys;
        }

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/deviceEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceEventDTOListPagedResponseFromJSON(jsonValue));
    }

    /**
     * Device Event Listeleme (Permission.DeviceEvent.List)
     */
    async getDeviceEvents(requestParameters: GetDeviceEventsRequest): Promise<DeviceEventDTOListPagedResponse> {
        const response = await this.getDeviceEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleEngineAlarm detayları (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarmRaw(requestParameters: GetRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRuleEngineAlarm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleEngineAlarmDTOFromJSON(jsonValue));
    }

    /**
     * RuleEngineAlarm detayları (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarm(requestParameters: GetRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO> {
        const response = await this.getRuleEngineAlarmRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleEngineAlarm Öneri Listeleme (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarmSuggestionsRaw(requestParameters: GetRuleEngineAlarmSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     * RuleEngineAlarm Öneri Listeleme (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarmSuggestions(requestParameters: GetRuleEngineAlarmSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getRuleEngineAlarmSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * RuleEngineAlarm Listeleme (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarmsRaw(requestParameters: GetRuleEngineAlarmsRequest): Promise<runtime.ApiResponse<Array<RuleEngineAlarmDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.deviceModelId !== undefined) {
            queryParameters['deviceModelId'] = requestParameters.deviceModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleEngineAlarmDTOFromJSON));
    }

    /**
     * RuleEngineAlarm Listeleme (Permission.RuleEngineAlarm.List)
     */
    async getRuleEngineAlarms(requestParameters: GetRuleEngineAlarmsRequest): Promise<Array<RuleEngineAlarmDTO>> {
        const response = await this.getRuleEngineAlarmsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Toplam Rule Engine Alarm Sayısı  (Permission.RuleEngineAlarm.List)
     */
    async getTotalAlarmCountRaw(requestParameters: GetTotalAlarmCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/totalAlarmCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Toplam Rule Engine Alarm Sayısı  (Permission.RuleEngineAlarm.List)
     */
    async getTotalAlarmCount(requestParameters: GetTotalAlarmCountRequest): Promise<number> {
        const response = await this.getTotalAlarmCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Toplam Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    async getTotalAlarmEventCountRaw(requestParameters: GetTotalAlarmEventCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/totalAlarmEventCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Toplam Alarm Event Sayısı (Permission.AlarmEvent.List)
     */
    async getTotalAlarmEventCount(requestParameters: GetTotalAlarmEventCountRequest): Promise<number> {
        const response = await this.getTotalAlarmEventCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Toplam  Device Event Sayısı (Permission.DeviceEvent.List)
     */
    async getTotalDeviceEventCountRaw(requestParameters: GetTotalDeviceEventCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/totalDeviceEventCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Toplam  Device Event Sayısı (Permission.DeviceEvent.List)
     */
    async getTotalDeviceEventCount(requestParameters: GetTotalDeviceEventCountRequest): Promise<number> {
        const response = await this.getTotalDeviceEventCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm Ekleme (Permission.RuleEngineAlarm.Add)
     */
    async postRuleEngineAlarmRaw(requestParameters: PostRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleEngineAlarmPostDTOToJSON(requestParameters.ruleEngineAlarmPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleEngineAlarmDTOFromJSON(jsonValue));
    }

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm Ekleme (Permission.RuleEngineAlarm.Add)
     */
    async postRuleEngineAlarm(requestParameters: PostRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO> {
        const response = await this.postRuleEngineAlarmRaw(requestParameters);
        return await response.value();
    }

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm güncelleme (Permission.RuleEngineAlarm.Edit)
     */
    async putRuleEngineAlarmRaw(requestParameters: PutRuleEngineAlarmRequest): Promise<runtime.ApiResponse<RuleEngineAlarmDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putRuleEngineAlarm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/ruleengine/Alarm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleEngineAlarmUpdateDTOToJSON(requestParameters.ruleEngineAlarmUpdateDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleEngineAlarmDTOFromJSON(jsonValue));
    }

    /**
     * Her tenantın cihaz model isimleri unique olmalı
     * RuleEngineAlarm güncelleme (Permission.RuleEngineAlarm.Edit)
     */
    async putRuleEngineAlarm(requestParameters: PutRuleEngineAlarmRequest): Promise<RuleEngineAlarmDTO> {
        const response = await this.putRuleEngineAlarmRaw(requestParameters);
        return await response.value();
    }

}
