/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    GuidIdentityUserClaim,
    GuidIdentityUserClaimFromJSON,
    GuidIdentityUserClaimFromJSONTyped,
    GuidIdentityUserClaimToJSON,
    GuidIdentityUserRole,
    GuidIdentityUserRoleFromJSON,
    GuidIdentityUserRoleFromJSONTyped,
    GuidIdentityUserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    userName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedUserName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedEmail?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    emailConfirmed?: boolean;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordHash?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    securityStamp?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    concurrencyStamp?: string | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    phoneNumberConfirmed?: boolean;

    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    twoFactorEnabled?: boolean;

    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lockoutEnd?: moment.Moment | null;

    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    lockoutEnabled?: boolean;

    /**
     * 
     * @type {number}
     * @memberof User
     */
    accessFailedCount?: number;

    /**
     * Navigation property for the roles this user belongs to.
     * @type {Array<GuidIdentityUserRole>}
     * @memberof User
     */
    roles?: Array<GuidIdentityUserRole> | null;

    /**
     * Navigation property for the claims this user possesses.
     * @type {Array<GuidIdentityUserClaim>}
     * @memberof User
     */
    claims?: Array<GuidIdentityUserClaim> | null;

    /**
     * 
     * @type {string}
     * @memberof User
     */
    tenantId?: string;

}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'normalizedUserName': !exists(json, 'normalizedUserName') ? undefined : json['normalizedUserName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'normalizedEmail': !exists(json, 'normalizedEmail') ? undefined : json['normalizedEmail'],
        'emailConfirmed': !exists(json, 'emailConfirmed') ? undefined : json['emailConfirmed'],
        'passwordHash': !exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'securityStamp': !exists(json, 'securityStamp') ? undefined : json['securityStamp'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'phoneNumberConfirmed': !exists(json, 'phoneNumberConfirmed') ? undefined : json['phoneNumberConfirmed'],
        'twoFactorEnabled': !exists(json, 'twoFactorEnabled') ? undefined : json['twoFactorEnabled'],
        'lockoutEnd': !exists(json, 'lockoutEnd') ? undefined : (json['lockoutEnd'] === null ? null : moment(json['lockoutEnd'])),

        'lockoutEnabled': !exists(json, 'lockoutEnabled') ? undefined : json['lockoutEnabled'],
        'accessFailedCount': !exists(json, 'accessFailedCount') ? undefined : json['accessFailedCount'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(GuidIdentityUserRoleFromJSON)),
        'claims': !exists(json, 'claims') ? undefined : (json['claims'] === null ? null : (json['claims'] as Array<any>).map(GuidIdentityUserClaimFromJSON)),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'normalizedUserName': value.normalizedUserName,
        'email': value.email,
        'normalizedEmail': value.normalizedEmail,
        'emailConfirmed': value.emailConfirmed,
        'passwordHash': value.passwordHash,
        'securityStamp': value.securityStamp,
        'concurrencyStamp': value.concurrencyStamp,
        'phoneNumber': value.phoneNumber,
        'phoneNumberConfirmed': value.phoneNumberConfirmed,
        'twoFactorEnabled': value.twoFactorEnabled,
        'lockoutEnd': value.lockoutEnd === undefined ? undefined : (value.lockoutEnd === null ? null : value.lockoutEnd.toISOString()),
        'lockoutEnabled': value.lockoutEnabled,
        'accessFailedCount': value.accessFailedCount,
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(GuidIdentityUserRoleToJSON)),
        'claims': value.claims === undefined ? undefined : (value.claims === null ? null : (value.claims as Array<any>).map(GuidIdentityUserClaimToJSON)),
        'tenantId': value.tenantId,
    };
}


