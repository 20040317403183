/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface TenantUpdateDTO
 */
export interface TenantUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    shortName?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof TenantUpdateDTO
     */
    disabled?: boolean;

    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    representative?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    contact?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    address?: string | null;

    /**
     * 
     * @type {string}
     * @memberof TenantUpdateDTO
     */
    id?: string;

}

export function TenantUpdateDTOFromJSON(json: any): TenantUpdateDTO {
    return TenantUpdateDTOFromJSONTyped(json, false);
}

export function TenantUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'representative': !exists(json, 'representative') ? undefined : json['representative'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function TenantUpdateDTOToJSON(value?: TenantUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortName': value.shortName,
        'disabled': value.disabled,
        'representative': value.representative,
        'contact': value.contact,
        'address': value.address,
        'id': value.id,
    };
}


