import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { AlarmEventDTO, DeviceEventDTO, RuleChainDTO, RuleChainPostDTO, RuleEngineAlarmDTO } from '../../api';
import { Moment } from 'moment';
import moment from 'moment';



class RuleEngineAlarmStore {
    rootStore: RootStore

    @observable totalDeviceEventCount: Number = 0
    @observable totalAlarmEventCount: Number = 0
    @observable totalAlarmCount: Number = 0

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }


    @action getTotalDeviceEventCount = async () => {
        this.totalDeviceEventCount = (await this.rootStore.api.alarmApi.getTotalDeviceEventCount({}))
    }
    @action getTotalAlarmEventCount = async () => {
        this.totalAlarmEventCount = (await this.rootStore.api.alarmApi.getTotalAlarmEventCount({}))
    }
    @action getTotalAlarmCount = async () => {
        this.totalAlarmCount = (await this.rootStore.api.alarmApi.getTotalAlarmCount({}))
    }
}

export default RuleEngineAlarmStore