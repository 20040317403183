/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";


export interface GetConnectedDevicesForTenantRequest {
    xTenantId?: string;
}

export interface GetIncomingMessageBytesForTenantRequest {
    xTenantId?: string;
}

export interface GetMessageMeterForTenantRequest {
    xTenantId?: string;
}

export interface GetOutgoingMessageBytesForTenantRequest {
    xTenantId?: string;
}

export interface GetTimeSeriesMeterForTenantRequest {
    xTenantId?: string;
}

/**
 * StatsApi - interface
 * 
 * @export
 * @interface StatsApiInterface
 */
export interface StatsApiInterface {
    /**
     * 
     * @summary Bağlı cihaz sayısı (Permission.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApiInterface
     */
    getConnectedDevicesForTenantRaw(requestParameters: GetConnectedDevicesForTenantRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Bağlı cihaz sayısı (Permission.Stats.List)
     */
    getConnectedDevicesForTenant(requestParameters: GetConnectedDevicesForTenantRequest): Promise<any>;

    /**
     * 
     * @summary Gelen  Mesaj bytes (Permission.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApiInterface
     */
    getIncomingMessageBytesForTenantRaw(requestParameters: GetIncomingMessageBytesForTenantRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Gelen  Mesaj bytes (Permission.Stats.List)
     */
    getIncomingMessageBytesForTenant(requestParameters: GetIncomingMessageBytesForTenantRequest): Promise<any>;

    /**
     * 
     * @summary Mesaj sayıları (Permission.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApiInterface
     */
    getMessageMeterForTenantRaw(requestParameters: GetMessageMeterForTenantRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Mesaj sayıları (Permission.Stats.List)
     */
    getMessageMeterForTenant(requestParameters: GetMessageMeterForTenantRequest): Promise<any>;

    /**
     * 
     * @summary Giden  Mesaj bytes (Permission.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApiInterface
     */
    getOutgoingMessageBytesForTenantRaw(requestParameters: GetOutgoingMessageBytesForTenantRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Giden  Mesaj bytes (Permission.Stats.List)
     */
    getOutgoingMessageBytesForTenant(requestParameters: GetOutgoingMessageBytesForTenantRequest): Promise<any>;

    /**
     * 
     * @summary Timeseries data (Permission.Stats.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApiInterface
     */
    getTimeSeriesMeterForTenantRaw(requestParameters: GetTimeSeriesMeterForTenantRequest): Promise<runtime.ApiResponse<any>>;

    /**
     * Timeseries data (Permission.Stats.List)
     */
    getTimeSeriesMeterForTenant(requestParameters: GetTimeSeriesMeterForTenantRequest): Promise<any>;

}

/**
 * 
 */
export class StatsApi extends runtime.BaseAPI implements StatsApiInterface {

    /**
     * Bağlı cihaz sayısı (Permission.Stats.List)
     */
    async getConnectedDevicesForTenantRaw(requestParameters: GetConnectedDevicesForTenantRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Stats/connecteddevices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Bağlı cihaz sayısı (Permission.Stats.List)
     */
    async getConnectedDevicesForTenant(requestParameters: GetConnectedDevicesForTenantRequest): Promise<any> {
        const response = await this.getConnectedDevicesForTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gelen  Mesaj bytes (Permission.Stats.List)
     */
    async getIncomingMessageBytesForTenantRaw(requestParameters: GetIncomingMessageBytesForTenantRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Stats/incomingmessagebytes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Gelen  Mesaj bytes (Permission.Stats.List)
     */
    async getIncomingMessageBytesForTenant(requestParameters: GetIncomingMessageBytesForTenantRequest): Promise<any> {
        const response = await this.getIncomingMessageBytesForTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Mesaj sayıları (Permission.Stats.List)
     */
    async getMessageMeterForTenantRaw(requestParameters: GetMessageMeterForTenantRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Stats/messagemeter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Mesaj sayıları (Permission.Stats.List)
     */
    async getMessageMeterForTenant(requestParameters: GetMessageMeterForTenantRequest): Promise<any> {
        const response = await this.getMessageMeterForTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Giden  Mesaj bytes (Permission.Stats.List)
     */
    async getOutgoingMessageBytesForTenantRaw(requestParameters: GetOutgoingMessageBytesForTenantRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Stats/outgoingmessagebytes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Giden  Mesaj bytes (Permission.Stats.List)
     */
    async getOutgoingMessageBytesForTenant(requestParameters: GetOutgoingMessageBytesForTenantRequest): Promise<any> {
        const response = await this.getOutgoingMessageBytesForTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Timeseries data (Permission.Stats.List)
     */
    async getTimeSeriesMeterForTenantRaw(requestParameters: GetTimeSeriesMeterForTenantRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Stats/timeseries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Timeseries data (Permission.Stats.List)
     */
    async getTimeSeriesMeterForTenant(requestParameters: GetTimeSeriesMeterForTenantRequest): Promise<any> {
        const response = await this.getTimeSeriesMeterForTenantRaw(requestParameters);
        return await response.value();
    }

}
