/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GuidIdentityUserClaim
 */
export interface GuidIdentityUserClaim {
    /**
     * 
     * @type {number}
     * @memberof GuidIdentityUserClaim
     */
    id?: number;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityUserClaim
     */
    userId?: string;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityUserClaim
     */
    claimType?: string | null;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityUserClaim
     */
    claimValue?: string | null;

}

export function GuidIdentityUserClaimFromJSON(json: any): GuidIdentityUserClaim {
    return GuidIdentityUserClaimFromJSONTyped(json, false);
}

export function GuidIdentityUserClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuidIdentityUserClaim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'claimType': !exists(json, 'claimType') ? undefined : json['claimType'],
        'claimValue': !exists(json, 'claimValue') ? undefined : json['claimValue'],
    };
}

export function GuidIdentityUserClaimToJSON(value?: GuidIdentityUserClaim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'claimType': value.claimType,
        'claimValue': value.claimValue,
    };
}


