/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CommandToDeviceLogStatus,
    CommandToDeviceLogStatusFromJSON,
    CommandToDeviceLogStatusFromJSONTyped,
    CommandToDeviceLogStatusToJSON,
} from './';

/**
 * Cihaza gönderilecek mesajların kayıtları Iot.Data.Models.CommandToDeviceLog
 * @export
 * @interface CommandToDeviceLogDTO
 */
export interface CommandToDeviceLogDTO {
    /**
     * 
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    deviceId?: string;

    /**
     * Ham veri şeklinde cevap mesajı
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    rawResponse?: string | null;

    /**
     * İşlenmiş cevap mesajı
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    response?: string | null;

    /**
     * Var ise cihazın mesaja verdiği Id
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    deviceMsgId?: string | null;

    /**
     * 
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    driver?: string | null;

    /**
     * Ham komut mesajı
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    rawCommand?: string | null;

    /**
     * Mesajın gönderildiği adres
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    transportAddress?: string | null;

    /**
     * Cevap mesajı gelme tarihi
     * @type {Date}
     * @memberof CommandToDeviceLogDTO
     */
    responseTimestamp?: moment.Moment | null;

    /**
     * Komutun oluşturulma tarihi
     * @type {Date}
     * @memberof CommandToDeviceLogDTO
     */
    timestamp?: moment.Moment | null;

    /**
     * Komutun cihaza gönderilme tarihi
     * @type {Date}
     * @memberof CommandToDeviceLogDTO
     */
    transportTimestamp?: moment.Moment | null;

    /**
     * 
     * @type {CommandToDeviceLogStatus}
     * @memberof CommandToDeviceLogDTO
     */
    status?: CommandToDeviceLogStatus;

    /**
     * Komut kodu
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    command?: string | null;

    /**
     * Gönderen bilgisi
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    sender?: string | null;

    /**
     * 
     * @type {string}
     * @memberof CommandToDeviceLogDTO
     */
    deviceName?: string | null;

}

export function CommandToDeviceLogDTOFromJSON(json: any): CommandToDeviceLogDTO {
    return CommandToDeviceLogDTOFromJSONTyped(json, false);
}

export function CommandToDeviceLogDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommandToDeviceLogDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'rawResponse': !exists(json, 'rawResponse') ? undefined : json['rawResponse'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'deviceMsgId': !exists(json, 'deviceMsgId') ? undefined : json['deviceMsgId'],
        'driver': !exists(json, 'driver') ? undefined : json['driver'],
        'rawCommand': !exists(json, 'rawCommand') ? undefined : json['rawCommand'],
        'transportAddress': !exists(json, 'transportAddress') ? undefined : json['transportAddress'],
        'responseTimestamp': !exists(json, 'responseTimestamp') ? undefined : (json['responseTimestamp'] === null ? null : moment(json['responseTimestamp'])),

        'timestamp': !exists(json, 'timestamp') ? undefined : (json['timestamp'] === null ? null : moment(json['timestamp'])),

        'transportTimestamp': !exists(json, 'transportTimestamp') ? undefined : (json['transportTimestamp'] === null ? null : moment(json['transportTimestamp'])),

        'status': !exists(json, 'status') ? undefined : CommandToDeviceLogStatusFromJSON(json['status']),
        'command': !exists(json, 'command') ? undefined : json['command'],
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'deviceName': !exists(json, 'deviceName') ? undefined : json['deviceName'],
    };
}

export function CommandToDeviceLogDTOToJSON(value?: CommandToDeviceLogDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'rawResponse': value.rawResponse,
        'response': value.response,
        'deviceMsgId': value.deviceMsgId,
        'driver': value.driver,
        'rawCommand': value.rawCommand,
        'transportAddress': value.transportAddress,
        'responseTimestamp': value.responseTimestamp === undefined ? undefined : (value.responseTimestamp === null ? null : value.responseTimestamp.toISOString()),
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp === null ? null : value.timestamp.toISOString()),
        'transportTimestamp': value.transportTimestamp === undefined ? undefined : (value.transportTimestamp === null ? null : value.transportTimestamp.toISOString()),
        'status': CommandToDeviceLogStatusToJSON(value.status),
        'command': value.command,
        'sender': value.sender,
        'deviceName': value.deviceName,
    };
}


