/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    Sensor,
    SensorFromJSON,
    SensorToJSON,
    SensorDTO,
    SensorDTOFromJSON,
    SensorDTOToJSON,
    SensorPostDTO,
    SensorPostDTOFromJSON,
    SensorPostDTOToJSON,
    SensorUpdateDTO,
    SensorUpdateDTOFromJSON,
    SensorUpdateDTOToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteSensorRequest {
    id: string;
    xTenantId?: string;
}

export interface GetSensorRequest {
    id: string;
    xTenantId?: string;
}

export interface GetSensorSuggestionsRequest {
    xTenantId?: string;
}

export interface GetSensorsRequest {
    xTenantId?: string;
}

export interface PostSensorRequest {
    xTenantId?: string;
    sensorPostDTO?: SensorPostDTO;
}

export interface PutSensorRequest {
    id: string;
    xTenantId?: string;
    sensorUpdateDTO?: SensorUpdateDTO;
}

/**
 * SensorApi - interface
 * 
 * @export
 * @interface SensorApiInterface
 */
export interface SensorApiInterface {
    /**
     * 
     * @summary Sensör  Silme
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    deleteSensorRaw(requestParameters: DeleteSensorRequest): Promise<runtime.ApiResponse<Sensor>>;

    /**
     * Sensör  Silme
     */
    deleteSensor(requestParameters: DeleteSensorRequest): Promise<Sensor>;

    /**
     * 
     * @summary Sensör Detay
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    getSensorRaw(requestParameters: GetSensorRequest): Promise<runtime.ApiResponse<SensorDTO>>;

    /**
     * Sensör Detay
     */
    getSensor(requestParameters: GetSensorRequest): Promise<SensorDTO>;

    /**
     * 
     * @summary Sensör öneri Listeleme
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    getSensorSuggestionsRaw(requestParameters: GetSensorSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>>;

    /**
     * Sensör öneri Listeleme
     */
    getSensorSuggestions(requestParameters: GetSensorSuggestionsRequest): Promise<Array<SuggestionDTO>>;

    /**
     * 
     * @summary Sensör Listeleme
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    getSensorsRaw(requestParameters: GetSensorsRequest): Promise<runtime.ApiResponse<Array<SensorDTO>>>;

    /**
     * Sensör Listeleme
     */
    getSensors(requestParameters: GetSensorsRequest): Promise<Array<SensorDTO>>;

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * @summary Sensör Ekleme
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {SensorPostDTO} [sensorPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    postSensorRaw(requestParameters: PostSensorRequest): Promise<runtime.ApiResponse<SensorPostDTO>>;

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Ekleme
     */
    postSensor(requestParameters: PostSensorRequest): Promise<SensorPostDTO>;

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * @summary Sensör Güncelleme
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {SensorUpdateDTO} [sensorUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApiInterface
     */
    putSensorRaw(requestParameters: PutSensorRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Güncelleme
     */
    putSensor(requestParameters: PutSensorRequest): Promise<void>;

}

/**
 * 
 */
export class SensorApi extends runtime.BaseAPI implements SensorApiInterface {

    /**
     * Sensör  Silme
     */
    async deleteSensorRaw(requestParameters: DeleteSensorRequest): Promise<runtime.ApiResponse<Sensor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSensor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SensorFromJSON(jsonValue));
    }

    /**
     * Sensör  Silme
     */
    async deleteSensor(requestParameters: DeleteSensorRequest): Promise<Sensor> {
        const response = await this.deleteSensorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sensör Detay
     */
    async getSensorRaw(requestParameters: GetSensorRequest): Promise<runtime.ApiResponse<SensorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSensor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SensorDTOFromJSON(jsonValue));
    }

    /**
     * Sensör Detay
     */
    async getSensor(requestParameters: GetSensorRequest): Promise<SensorDTO> {
        const response = await this.getSensorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sensör öneri Listeleme
     */
    async getSensorSuggestionsRaw(requestParameters: GetSensorSuggestionsRequest): Promise<runtime.ApiResponse<Array<SuggestionDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SuggestionDTOFromJSON));
    }

    /**
     * Sensör öneri Listeleme
     */
    async getSensorSuggestions(requestParameters: GetSensorSuggestionsRequest): Promise<Array<SuggestionDTO>> {
        const response = await this.getSensorSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sensör Listeleme
     */
    async getSensorsRaw(requestParameters: GetSensorsRequest): Promise<runtime.ApiResponse<Array<SensorDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SensorDTOFromJSON));
    }

    /**
     * Sensör Listeleme
     */
    async getSensors(requestParameters: GetSensorsRequest): Promise<Array<SensorDTO>> {
        const response = await this.getSensorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Ekleme
     */
    async postSensorRaw(requestParameters: PostSensorRequest): Promise<runtime.ApiResponse<SensorPostDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SensorPostDTOToJSON(requestParameters.sensorPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SensorPostDTOFromJSON(jsonValue));
    }

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Ekleme
     */
    async postSensor(requestParameters: PostSensorRequest): Promise<SensorPostDTO> {
        const response = await this.postSensorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Güncelleme
     */
    async putSensorRaw(requestParameters: PutSensorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putSensor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Sensor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SensorUpdateDTOToJSON(requestParameters.sensorUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Her tenantın sensörlerinin adı unique olmalı
     * Sensör Güncelleme
     */
    async putSensor(requestParameters: PutSensorRequest): Promise<void> {
        await this.putSensorRaw(requestParameters);
    }

}
