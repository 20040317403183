/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GuidIdentityRoleClaim
 */
export interface GuidIdentityRoleClaim {
    /**
     * 
     * @type {number}
     * @memberof GuidIdentityRoleClaim
     */
    id?: number;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityRoleClaim
     */
    roleId?: string;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityRoleClaim
     */
    claimType?: string | null;

    /**
     * 
     * @type {string}
     * @memberof GuidIdentityRoleClaim
     */
    claimValue?: string | null;

}

export function GuidIdentityRoleClaimFromJSON(json: any): GuidIdentityRoleClaim {
    return GuidIdentityRoleClaimFromJSONTyped(json, false);
}

export function GuidIdentityRoleClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuidIdentityRoleClaim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'claimType': !exists(json, 'claimType') ? undefined : json['claimType'],
        'claimValue': !exists(json, 'claimValue') ? undefined : json['claimValue'],
    };
}

export function GuidIdentityRoleClaimToJSON(value?: GuidIdentityRoleClaim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'roleId': value.roleId,
        'claimType': value.claimType,
        'claimValue': value.claimValue,
    };
}


