import TenantStore from './data/TenantStore';
import RootStore from './RootStore';
import UserStore from './data/UserStore';
import RoleStore from './data/RoleStore';
import DriverStore from './data/DriverStore';
import DeviceStore from './data/DeviceStore';
import RuleEngineAlarmStore from './data/RuleEngineAlarmStore';


class DataStore {
    rootStore: RootStore


    tenant: TenantStore
    driver: DriverStore
    user: UserStore
    role: RoleStore
    device: DeviceStore
    alarm: RuleEngineAlarmStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        this.tenant = new TenantStore(rootStore)
        this.user = new UserStore(rootStore)
        this.role = new RoleStore(rootStore)
        this.driver = new DriverStore(rootStore)
        this.device = new DeviceStore(rootStore)
        this.alarm = new RuleEngineAlarmStore(rootStore)

    }
}


export default DataStore