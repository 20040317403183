/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    TenantDTO,
    TenantDTOFromJSON,
    TenantDTOToJSON,
    TenantPostDTO,
    TenantPostDTOFromJSON,
    TenantPostDTOToJSON,
    TenantUpdateDTO,
    TenantUpdateDTOFromJSON,
    TenantUpdateDTOToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface DeleteTenantRequest {
    id: string;
    xTenantId?: string;
}

export interface GetTenantRequest {
    id: string;
    xTenantId?: string;
}

export interface GetTenantCountRequest {
    xTenantId?: string;
}

export interface GetTenantsRequest {
    xTenantId?: string;
}

export interface GetUserOwnTenantRequest {
    xTenantId?: string;
}

export interface PostTenantRequest {
    xTenantId?: string;
    tenantPostDTO?: TenantPostDTO;
}

export interface PostTenantLogoRequest {
    id: string;
    xTenantId?: string;
    file?: Blob;
}

export interface PutTenantRequest {
    id: string;
    xTenantId?: string;
    tenantUpdateDTO?: TenantUpdateDTO;
}

/**
 * TenantApi - interface
 * 
 * @export
 * @interface TenantApiInterface
 */
export interface TenantApiInterface {
    /**
     * 
     * @summary Tenant  Silme (Permission.Host.Tenant.Delete)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    deleteTenantRaw(requestParameters: DeleteTenantRequest): Promise<runtime.ApiResponse<Tenant>>;

    /**
     * Tenant  Silme (Permission.Host.Tenant.Delete)
     */
    deleteTenant(requestParameters: DeleteTenantRequest): Promise<Tenant>;

    /**
     * 
     * @summary Tenant Detay (Permission.Host.Tenant.List)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantRaw(requestParameters: GetTenantRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     * Tenant Detay (Permission.Host.Tenant.List)
     */
    getTenant(requestParameters: GetTenantRequest): Promise<TenantDTO>;

    /**
     * 
     * @summary Tenant Sayısı (Permission.Host.Tenant.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantCountRaw(requestParameters: GetTenantCountRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Tenant Sayısı (Permission.Host.Tenant.List)
     */
    getTenantCount(requestParameters: GetTenantCountRequest): Promise<number>;

    /**
     * 
     * @summary Tenant Listeleme (Permission.Host.Tenant.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getTenantsRaw(requestParameters: GetTenantsRequest): Promise<runtime.ApiResponse<Array<TenantDTO>>>;

    /**
     * Tenant Listeleme (Permission.Host.Tenant.List)
     */
    getTenants(requestParameters: GetTenantsRequest): Promise<Array<TenantDTO>>;

    /**
     * 
     * @summary Userin Tenant bilgisi (Permission.Host.Tenant.List)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    getUserOwnTenantRaw(requestParameters: GetUserOwnTenantRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     * Userin Tenant bilgisi (Permission.Host.Tenant.List)
     */
    getUserOwnTenant(requestParameters: GetUserOwnTenantRequest): Promise<TenantDTO>;

    /**
     * 
     * @summary Tenant Ekleme (Permission.Host.Tenant.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {TenantPostDTO} [tenantPostDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    postTenantRaw(requestParameters: PostTenantRequest): Promise<runtime.ApiResponse<Tenant>>;

    /**
     * Tenant Ekleme (Permission.Host.Tenant.Add)
     */
    postTenant(requestParameters: PostTenantRequest): Promise<Tenant>;

    /**
     * 
     * @summary Tenant logo upload (Permission.Host.Tenant.Edit)
     * @param {string} id tenantId
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    postTenantLogoRaw(requestParameters: PostTenantLogoRequest): Promise<runtime.ApiResponse<TenantDTO>>;

    /**
     * Tenant logo upload (Permission.Host.Tenant.Edit)
     */
    postTenantLogo(requestParameters: PostTenantLogoRequest): Promise<TenantDTO>;

    /**
     * 
     * @summary Tenant Güncelleme (Permission.Host.Tenant.Edit)
     * @param {string} id 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {TenantUpdateDTO} [tenantUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApiInterface
     */
    putTenantRaw(requestParameters: PutTenantRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Tenant Güncelleme (Permission.Host.Tenant.Edit)
     */
    putTenant(requestParameters: PutTenantRequest): Promise<void>;

}

/**
 * 
 */
export class TenantApi extends runtime.BaseAPI implements TenantApiInterface {

    /**
     * Tenant  Silme (Permission.Host.Tenant.Delete)
     */
    async deleteTenantRaw(requestParameters: DeleteTenantRequest): Promise<runtime.ApiResponse<Tenant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     * Tenant  Silme (Permission.Host.Tenant.Delete)
     */
    async deleteTenant(requestParameters: DeleteTenantRequest): Promise<Tenant> {
        const response = await this.deleteTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant Detay (Permission.Host.Tenant.List)
     */
    async getTenantRaw(requestParameters: GetTenantRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     * Tenant Detay (Permission.Host.Tenant.List)
     */
    async getTenant(requestParameters: GetTenantRequest): Promise<TenantDTO> {
        const response = await this.getTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant Sayısı (Permission.Host.Tenant.List)
     */
    async getTenantCountRaw(requestParameters: GetTenantCountRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant/tenantCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Tenant Sayısı (Permission.Host.Tenant.List)
     */
    async getTenantCount(requestParameters: GetTenantCountRequest): Promise<number> {
        const response = await this.getTenantCountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant Listeleme (Permission.Host.Tenant.List)
     */
    async getTenantsRaw(requestParameters: GetTenantsRequest): Promise<runtime.ApiResponse<Array<TenantDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantDTOFromJSON));
    }

    /**
     * Tenant Listeleme (Permission.Host.Tenant.List)
     */
    async getTenants(requestParameters: GetTenantsRequest): Promise<Array<TenantDTO>> {
        const response = await this.getTenantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Userin Tenant bilgisi (Permission.Host.Tenant.List)
     */
    async getUserOwnTenantRaw(requestParameters: GetUserOwnTenantRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant/own`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     * Userin Tenant bilgisi (Permission.Host.Tenant.List)
     */
    async getUserOwnTenant(requestParameters: GetUserOwnTenantRequest): Promise<TenantDTO> {
        const response = await this.getUserOwnTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant Ekleme (Permission.Host.Tenant.Add)
     */
    async postTenantRaw(requestParameters: PostTenantRequest): Promise<runtime.ApiResponse<Tenant>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantPostDTOToJSON(requestParameters.tenantPostDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     * Tenant Ekleme (Permission.Host.Tenant.Add)
     */
    async postTenant(requestParameters: PostTenantRequest): Promise<Tenant> {
        const response = await this.postTenantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant logo upload (Permission.Host.Tenant.Edit)
     */
    async postTenantLogoRaw(requestParameters: PostTenantLogoRequest): Promise<runtime.ApiResponse<TenantDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postTenantLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/iot/Tenant/{id}/uploadLogo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantDTOFromJSON(jsonValue));
    }

    /**
     * Tenant logo upload (Permission.Host.Tenant.Edit)
     */
    async postTenantLogo(requestParameters: PostTenantLogoRequest): Promise<TenantDTO> {
        const response = await this.postTenantLogoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Tenant Güncelleme (Permission.Host.Tenant.Edit)
     */
    async putTenantRaw(requestParameters: PutTenantRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TenantUpdateDTOToJSON(requestParameters.tenantUpdateDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Tenant Güncelleme (Permission.Host.Tenant.Edit)
     */
    async putTenant(requestParameters: PutTenantRequest): Promise<void> {
        await this.putTenantRaw(requestParameters);
    }

}
