import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { Tenant, TenantPostDTO, TenantUpdateDTO } from '../../api';



class TenantStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: Tenant[] = []
    @observable tenantCount: Number = 0

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }

    @action findTenant = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.tenantApi.getTenants({}))
        this.isLoading = false
    }

    @action add = async (d: TenantPostDTO) => {
        let newD = (await this.rootStore.api.tenantApi.postTenant({ tenantPostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: TenantUpdateDTO) => {
        (await this.rootStore.api.tenantApi.putTenant({ id: d.id!, tenantUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.tenantApi.deleteTenant({ id: id })
        } catch {
            this.getList()
        }
    }

    @action getTenantCount = async () => {
        this.tenantCount = (await this.rootStore.api.tenantApi.getTenantCount({}))
    }
}

export default TenantStore