/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    Driver,
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './';

/**
 * Cihaz modeli
 * @export
 * @interface DeviceModel
 */
export interface DeviceModel {
    /**
     * 
     * @type {Date}
     * @memberof DeviceModel
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceModel
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof DeviceModel
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    driverClassName?: string | null;

    /**
     * 
     * @type {Driver}
     * @memberof DeviceModel
     */
    driver?: Driver;

    /**
     * 
     * @type {string}
     * @memberof DeviceModel
     */
    tenantId?: string;

}

export function DeviceModelFromJSON(json: any): DeviceModel {
    return DeviceModelFromJSONTyped(json, false);
}

export function DeviceModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'driverClassName': !exists(json, 'driverClassName') ? undefined : json['driverClassName'],
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function DeviceModelToJSON(value?: DeviceModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'driverClassName': value.driverClassName,
        'driver': DriverToJSON(value.driver),
        'tenantId': value.tenantId,
    };
}


