/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    RuleChain,
    RuleChainFromJSON,
    RuleChainFromJSONTyped,
    RuleChainToJSON,
} from './';

/**
 * Kural motoru içerisindeki işlem yapan node
 * @export
 * @interface RuleNode
 */
export interface RuleNode {
    /**
     * 
     * @type {Date}
     * @memberof RuleNode
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof RuleNode
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof RuleNode
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleNode
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof RuleNode
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof RuleNode
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNode
     */
    ruleChainId?: string;

    /**
     * 
     * @type {RuleChain}
     * @memberof RuleNode
     */
    ruleChain?: RuleChain;

    /**
     * Ek parametreler. "Script" node ise kod, "Message Select" node ise mesaj türü bulunabilir.
     * @type {any}
     * @memberof RuleNode
     */
    _configuration?: any | null;

    /**
     * Arayüz için Ek parametreler.
     * @type {any}
     * @memberof RuleNode
     */
    uiConfiguration?: any | null;

    /**
     * Görsel ismi
     * @type {string}
     * @memberof RuleNode
     */
    name?: string | null;

    /**
     * Node hangi class ile çalıştığını belirtir. Rule engine içersinde implementasyonu olmalıdır.
     * TODO: enum yapılabilir
     * @type {string}
     * @memberof RuleNode
     */
    type?: string | null;

}

export function RuleNodeFromJSON(json: any): RuleNode {
    return RuleNodeFromJSONTyped(json, false);
}

export function RuleNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        'ruleChain': !exists(json, 'ruleChain') ? undefined : RuleChainFromJSON(json['ruleChain']),
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'uiConfiguration': !exists(json, 'uiConfiguration') ? undefined : json['uiConfiguration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeToJSON(value?: RuleNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'ruleChainId': value.ruleChainId,
        'ruleChain': RuleChainToJSON(value.ruleChain),
        'configuration': value._configuration,
        'uiConfiguration': value.uiConfiguration,
        'name': value.name,
        'type': value.type,
    };
}


