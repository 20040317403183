/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    Driver,
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './';

/**
 * Cihaz modeli
 * @export
 * @interface DeviceModelDTO
 */
export interface DeviceModelDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceModelDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelDTO
     */
    driverClassName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof DeviceModelDTO
     */
    id?: string;

    /**
     * 
     * @type {Date}
     * @memberof DeviceModelDTO
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof DeviceModelDTO
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {Driver}
     * @memberof DeviceModelDTO
     */
    driver?: Driver;

}

export function DeviceModelDTOFromJSON(json: any): DeviceModelDTO {
    return DeviceModelDTOFromJSONTyped(json, false);
}

export function DeviceModelDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceModelDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'driverClassName': !exists(json, 'driverClassName') ? undefined : json['driverClassName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
    };
}

export function DeviceModelDTOToJSON(value?: DeviceModelDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'driverClassName': value.driverClassName,
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'driver': DriverToJSON(value.driver),
    };
}


