/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    TelemetryType,
    TelemetryTypeFromJSON,
    TelemetryTypeFromJSONTyped,
    TelemetryTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SensorUpdateDTO
 */
export interface SensorUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof SensorUpdateDTO
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof SensorUpdateDTO
     */
    typeKey?: string | null;

    /**
     * 
     * @type {TelemetryType}
     * @memberof SensorUpdateDTO
     */
    telemetryType?: TelemetryType;

    /**
     * 
     * @type {string}
     * @memberof SensorUpdateDTO
     */
    description?: string | null;

    /**
     * 
     * @type {string}
     * @memberof SensorUpdateDTO
     */
    id?: string;

}

export function SensorUpdateDTOFromJSON(json: any): SensorUpdateDTO {
    return SensorUpdateDTOFromJSONTyped(json, false);
}

export function SensorUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'typeKey': !exists(json, 'typeKey') ? undefined : json['typeKey'],
        'telemetryType': !exists(json, 'telemetryType') ? undefined : TelemetryTypeFromJSON(json['telemetryType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function SensorUpdateDTOToJSON(value?: SensorUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'typeKey': value.typeKey,
        'telemetryType': TelemetryTypeToJSON(value.telemetryType),
        'description': value.description,
        'id': value.id,
    };
}


