import { grey } from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import baseTheme from './base';


const lightTheme = createMuiTheme({
    props: {

    },
    overrides: {

    },
    palette: {
        primary: {
            main: "#f44336",
        },
        secondary: {
            main: '#995A00',
        },
        type: "light",
        background: {
            default: '#FAFAFA',
            level1: '#fff',
            level2: grey[100]
        },

    }
}, baseTheme)

export default lightTheme