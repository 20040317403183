/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    Claim,
    ClaimFromJSON,
    ClaimFromJSONTyped,
    ClaimToJSON,
    CustomFilter,
    CustomFilterFromJSON,
    CustomFilterFromJSONTyped,
    CustomFilterToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    DeviceGroup,
    DeviceGroupFromJSON,
    DeviceGroupFromJSONTyped,
    DeviceGroupToJSON,
    RuleChain,
    RuleChainFromJSON,
    RuleChainFromJSONTyped,
    RuleChainToJSON,
    RuleEngineAlarm,
    RuleEngineAlarmFromJSON,
    RuleEngineAlarmFromJSONTyped,
    RuleEngineAlarmToJSON,
    Sensor,
    SensorFromJSON,
    SensorFromJSONTyped,
    SensorToJSON,
    TimeSeriesData,
    TimeSeriesDataFromJSON,
    TimeSeriesDataFromJSONTyped,
    TimeSeriesDataToJSON,
    TimeSeriesDataLatest,
    TimeSeriesDataLatestFromJSON,
    TimeSeriesDataLatestFromJSONTyped,
    TimeSeriesDataLatestToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    createdDate?: moment.Moment;

    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    updatedDate?: moment.Moment;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    createdById?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    updatedById?: string | null;

    /**
     * 
     * @type {CustomFilter}
     * @memberof Tenant
     */
    customFilterAttributes?: CustomFilter;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    shortName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    logo?: string | null;

    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    mainTenant?: boolean;

    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    disabled?: boolean;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    representative?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    contact?: string | null;

    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    address?: string | null;

    /**
     * 
     * @type {Array<Device>}
     * @memberof Tenant
     */
    devices?: Array<Device> | null;

    /**
     * 
     * @type {Array<DeviceGroup>}
     * @memberof Tenant
     */
    deviceGroups?: Array<DeviceGroup> | null;

    /**
     * 
     * @type {Array<Sensor>}
     * @memberof Tenant
     */
    sensors?: Array<Sensor> | null;

    /**
     * 
     * @type {Array<RuleChain>}
     * @memberof Tenant
     */
    ruleChains?: Array<RuleChain> | null;

    /**
     * 
     * @type {Array<RuleEngineAlarm>}
     * @memberof Tenant
     */
    ruleEngineAlarms?: Array<RuleEngineAlarm> | null;

    /**
     * 
     * @type {Array<TimeSeriesData>}
     * @memberof Tenant
     */
    timeSeriesData?: Array<TimeSeriesData> | null;

    /**
     * 
     * @type {Array<TimeSeriesDataLatest>}
     * @memberof Tenant
     */
    timeSeriesDataLatest?: Array<TimeSeriesDataLatest> | null;

    /**
     * 
     * @type {Array<Claim>}
     * @memberof Tenant
     */
    readonly permittedClaims?: Array<Claim> | null;

}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': !exists(json, 'createdDate') ? undefined : (moment(json['createdDate'])),

        'updatedDate': !exists(json, 'updatedDate') ? undefined : (moment(json['updatedDate'])),

        'createdById': !exists(json, 'createdById') ? undefined : json['createdById'],
        'updatedById': !exists(json, 'updatedById') ? undefined : json['updatedById'],
        'customFilterAttributes': !exists(json, 'customFilterAttributes') ? undefined : CustomFilterFromJSON(json['customFilterAttributes']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'mainTenant': !exists(json, 'mainTenant') ? undefined : json['mainTenant'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'representative': !exists(json, 'representative') ? undefined : json['representative'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'devices': !exists(json, 'devices') ? undefined : (json['devices'] === null ? null : (json['devices'] as Array<any>).map(DeviceFromJSON)),
        'deviceGroups': !exists(json, 'deviceGroups') ? undefined : (json['deviceGroups'] === null ? null : (json['deviceGroups'] as Array<any>).map(DeviceGroupFromJSON)),
        'sensors': !exists(json, 'sensors') ? undefined : (json['sensors'] === null ? null : (json['sensors'] as Array<any>).map(SensorFromJSON)),
        'ruleChains': !exists(json, 'ruleChains') ? undefined : (json['ruleChains'] === null ? null : (json['ruleChains'] as Array<any>).map(RuleChainFromJSON)),
        'ruleEngineAlarms': !exists(json, 'ruleEngineAlarms') ? undefined : (json['ruleEngineAlarms'] === null ? null : (json['ruleEngineAlarms'] as Array<any>).map(RuleEngineAlarmFromJSON)),
        'timeSeriesData': !exists(json, 'timeSeriesData') ? undefined : (json['timeSeriesData'] === null ? null : (json['timeSeriesData'] as Array<any>).map(TimeSeriesDataFromJSON)),
        'timeSeriesDataLatest': !exists(json, 'timeSeriesDataLatest') ? undefined : (json['timeSeriesDataLatest'] === null ? null : (json['timeSeriesDataLatest'] as Array<any>).map(TimeSeriesDataLatestFromJSON)),
        'permittedClaims': !exists(json, 'permittedClaims') ? undefined : (json['permittedClaims'] === null ? null : (json['permittedClaims'] as Array<any>).map(ClaimFromJSON)),
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'createdById': value.createdById,
        'updatedById': value.updatedById,
        'customFilterAttributes': CustomFilterToJSON(value.customFilterAttributes),
        'id': value.id,
        'name': value.name,
        'shortName': value.shortName,
        'logo': value.logo,
        'mainTenant': value.mainTenant,
        'disabled': value.disabled,
        'representative': value.representative,
        'contact': value.contact,
        'address': value.address,
        'devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceToJSON)),
        'deviceGroups': value.deviceGroups === undefined ? undefined : (value.deviceGroups === null ? null : (value.deviceGroups as Array<any>).map(DeviceGroupToJSON)),
        'sensors': value.sensors === undefined ? undefined : (value.sensors === null ? null : (value.sensors as Array<any>).map(SensorToJSON)),
        'ruleChains': value.ruleChains === undefined ? undefined : (value.ruleChains === null ? null : (value.ruleChains as Array<any>).map(RuleChainToJSON)),
        'ruleEngineAlarms': value.ruleEngineAlarms === undefined ? undefined : (value.ruleEngineAlarms === null ? null : (value.ruleEngineAlarms as Array<any>).map(RuleEngineAlarmToJSON)),
        'timeSeriesData': value.timeSeriesData === undefined ? undefined : (value.timeSeriesData === null ? null : (value.timeSeriesData as Array<any>).map(TimeSeriesDataToJSON)),
        'timeSeriesDataLatest': value.timeSeriesDataLatest === undefined ? undefined : (value.timeSeriesDataLatest === null ? null : (value.timeSeriesDataLatest as Array<any>).map(TimeSeriesDataLatestToJSON)),
    };
}


