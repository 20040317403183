import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { RoleDTO, RolePostDTO } from '../../api';



class RoleStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: RoleDTO[] = []
    @observable roleFilter: string[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action findRole = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = await this.rootStore.api.userApi.getRoles({})
        this.isLoading = false
    }

    @action add = async (d: RolePostDTO) => {
        let newD = (await this.rootStore.api.userApi.postRole({ rolePostDTO: d }))
        this.list.unshift(newD)
    }


    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.userApi.deleteRole({ id: id })
        } catch {
            this.getList()
        }
    }
}

export default RoleStore