/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    DeviceEventDTO,
    DeviceEventDTOFromJSON,
    DeviceEventDTOFromJSONTyped,
    DeviceEventDTOToJSON,
} from './';

/**
 * Pagination yardımcı classı
 * @export
 * @interface DeviceEventDTOListPagedResponse
 */
export interface DeviceEventDTOListPagedResponse {
    /**
     * 
     * @type {Array<DeviceEventDTO>}
     * @memberof DeviceEventDTOListPagedResponse
     */
    data?: Array<DeviceEventDTO> | null;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTOListPagedResponse
     */
    pageNumber?: number;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTOListPagedResponse
     */
    pageSize?: number;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTOListPagedResponse
     */
    totalPages?: number;

    /**
     * 
     * @type {number}
     * @memberof DeviceEventDTOListPagedResponse
     */
    totalRecords?: number;

}

export function DeviceEventDTOListPagedResponseFromJSON(json: any): DeviceEventDTOListPagedResponse {
    return DeviceEventDTOListPagedResponseFromJSONTyped(json, false);
}

export function DeviceEventDTOListPagedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceEventDTOListPagedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(DeviceEventDTOFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
    };
}

export function DeviceEventDTOListPagedResponseToJSON(value?: DeviceEventDTOListPagedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(DeviceEventDTOToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'totalRecords': value.totalRecords,
    };
}


