import { observable, makeObservable, computed, action } from "mobx";
import { Driver } from "../../api";
import RootStore from "../RootStore";

class DriverStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: Driver[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.className, value: d.className }))
    }

    @action findDeviceModel = (id: string) => {
        return this.list.find(o => o.className === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.driver.getDrivers({}))
        this.isLoading = false
    }

    @action add = async (d: Driver) => {
        let newD = (await this.rootStore.api.driver.postDriver({ driver: d }))
        this.list.unshift(newD)

    }

    @action update = async (d: Driver) => {
        (await this.rootStore.api.driver.putDriver({ className: d.className!, driver: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.className === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.driver.deleteDriver({ className: id })
        } catch {
            this.getList()
        }
    }
}

export default DriverStore