/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RuleEngineAlarmConfiguration,
    RuleEngineAlarmConfigurationFromJSON,
    RuleEngineAlarmConfigurationFromJSONTyped,
    RuleEngineAlarmConfigurationToJSON,
    SuggestionDTO,
    SuggestionDTOFromJSON,
    SuggestionDTOFromJSONTyped,
    SuggestionDTOToJSON,
} from './';

/**
 * Kural motoru alarmları, Cihaz verileri üzerinde basit kurallar ile Iot.Data.Models.AlarmEvent oluşturur.
 * @export
 * @interface RuleEngineAlarmDTO
 */
export interface RuleEngineAlarmDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    id?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    tenantId?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    tenantName?: string | null;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    deviceId?: string | null;

    /**
     * 
     * @type {SuggestionDTO}
     * @memberof RuleEngineAlarmDTO
     */
    device?: SuggestionDTO;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    deviceGroupId?: string | null;

    /**
     * 
     * @type {SuggestionDTO}
     * @memberof RuleEngineAlarmDTO
     */
    deviceGroup?: SuggestionDTO;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    deviceModelId?: string | null;

    /**
     * 
     * @type {SuggestionDTO}
     * @memberof RuleEngineAlarmDTO
     */
    deviceModel?: SuggestionDTO;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    name?: string | null;

    /**
     * 
     * @type {RuleEngineAlarmConfiguration}
     * @memberof RuleEngineAlarmDTO
     */
    _configuration?: RuleEngineAlarmConfiguration;

    /**
     * 
     * @type {string}
     * @memberof RuleEngineAlarmDTO
     */
    description?: string | null;

}

export function RuleEngineAlarmDTOFromJSON(json: any): RuleEngineAlarmDTO {
    return RuleEngineAlarmDTOFromJSONTyped(json, false);
}

export function RuleEngineAlarmDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleEngineAlarmDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'device': !exists(json, 'device') ? undefined : SuggestionDTOFromJSON(json['device']),
        'deviceGroupId': !exists(json, 'deviceGroupId') ? undefined : json['deviceGroupId'],
        'deviceGroup': !exists(json, 'deviceGroup') ? undefined : SuggestionDTOFromJSON(json['deviceGroup']),
        'deviceModelId': !exists(json, 'deviceModelId') ? undefined : json['deviceModelId'],
        'deviceModel': !exists(json, 'deviceModel') ? undefined : SuggestionDTOFromJSON(json['deviceModel']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_configuration': !exists(json, 'configuration') ? undefined : RuleEngineAlarmConfigurationFromJSON(json['configuration']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RuleEngineAlarmDTOToJSON(value?: RuleEngineAlarmDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'tenantName': value.tenantName,
        'deviceId': value.deviceId,
        'device': SuggestionDTOToJSON(value.device),
        'deviceGroupId': value.deviceGroupId,
        'deviceGroup': SuggestionDTOToJSON(value.deviceGroup),
        'deviceModelId': value.deviceModelId,
        'deviceModel': SuggestionDTOToJSON(value.deviceModel),
        'name': value.name,
        'configuration': RuleEngineAlarmConfigurationToJSON(value._configuration),
        'description': value.description,
    };
}


