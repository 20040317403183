
export interface IPages {
    pathname: string,
    oneOfPermission?: string[],
    permission?: string,
    displayNav?: boolean,
    rental?: boolean,
    title: string | boolean,
    children?: IPages[]
}
export const pages: IPages[] = [
    {
        pathname: '404',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'login',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'force',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'relogin',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'dashboard',
        title: "home",
    },
    {
        pathname: 'definitions.tenant',
        title: 'definitions.tenant.title',
    },/*
    {
        pathname: 'reports',
        title: "reports",
    },*/
    {
        pathname: 'definitions',
        title: "definitions.title",

        children: [

            {
                pathname: 'definitions.driver',
                title: 'definitions.driver.title',
            },

            {
                pathname: 'definitions.user',
                title: 'definitions.user.title',
            },
            {
                pathname: 'definitions.role',
                title: 'definitions.role.title',
            }
        ],


    },


];