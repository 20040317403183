import { grey } from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import baseTheme from './base';


const darkTheme = createMuiTheme({
    props: {

    },
    overrides: {

    },
    palette: {
        primary: {
            main: "#b2102f",
        },
        secondary: {
            main: '#995A00',
        },
        type: "dark",
        background: {
            default: '#121212',
            level1: grey[900],
            level2: "#333"
        },

    }
}, baseTheme)

export default darkTheme