/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * Iot.Data.Models.RuleChain içerisindeki Iot.Data.Models.RuleNode ilişkileri
 * @export
 * @interface RuleNodeRelationUpdateDTO
 */
export interface RuleNodeRelationUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationUpdateDTO
     */
    ruleChainId?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationUpdateDTO
     */
    fromId?: string;

    /**
     * 
     * @type {string}
     * @memberof RuleNodeRelationUpdateDTO
     */
    toId?: string;

    /**
     * İlişki tipi, çok çıkışlı nodelarda çıkışları ayırt etmek için.
     * @type {string}
     * @memberof RuleNodeRelationUpdateDTO
     */
    type?: string | null;

}

export function RuleNodeRelationUpdateDTOFromJSON(json: any): RuleNodeRelationUpdateDTO {
    return RuleNodeRelationUpdateDTOFromJSONTyped(json, false);
}

export function RuleNodeRelationUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleNodeRelationUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleChainId': !exists(json, 'ruleChainId') ? undefined : json['ruleChainId'],
        'fromId': !exists(json, 'fromId') ? undefined : json['fromId'],
        'toId': !exists(json, 'toId') ? undefined : json['toId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function RuleNodeRelationUpdateDTOToJSON(value?: RuleNodeRelationUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleChainId': value.ruleChainId,
        'fromId': value.fromId,
        'toId': value.toId,
        'type': value.type,
    };
}


