import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceDTO } from '../../api';



class DeviceStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: DeviceDTO[] = []
    @observable deviceGroupFilter: string[] = []
    @observable deviceModelFilter: string = ""
    @observable totalDeviceCount: Number = 0

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }


    @action getTotalDeviceCount = async () => {
        this.totalDeviceCount = (await this.rootStore.api.deviceApi.getTotalDeviceCount({}))
    }
}

export default DeviceStore