/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
} from '../models';

export interface GetDeviceExampleFileRequest {
    xTenantId?: string;
}

export interface PostDeviceExcelFileRequest {
    xTenantId?: string;
    inlineObject?: InlineObject;
}

/**
 * ImportApi - interface
 * 
 * @export
 * @interface ImportApiInterface
 */
export interface ImportApiInterface {
    /**
     * Örnek olarak bir cihazlar excel dosyası indirmektedir.
     * @summary Example Device Excel File
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    getDeviceExampleFileRaw(requestParameters: GetDeviceExampleFileRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Örnek olarak bir cihazlar excel dosyası indirmektedir.
     * Example Device Excel File
     */
    getDeviceExampleFile(requestParameters: GetDeviceExampleFileRequest): Promise<Blob>;

    /**
     * Excel dosyası olarak alınan cihaz listesindeki cihazları Device tablosuna ekler.
     * @summary Import Device Excel File (Permission.Device.Add)
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    postDeviceExcelFileRaw(requestParameters: PostDeviceExcelFileRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Excel dosyası olarak alınan cihaz listesindeki cihazları Device tablosuna ekler.
     * Import Device Excel File (Permission.Device.Add)
     */
    postDeviceExcelFile(requestParameters: PostDeviceExcelFileRequest): Promise<Blob>;

}

/**
 * 
 */
export class ImportApi extends runtime.BaseAPI implements ImportApiInterface {

    /**
     * Örnek olarak bir cihazlar excel dosyası indirmektedir.
     * Example Device Excel File
     */
    async getDeviceExampleFileRaw(requestParameters: GetDeviceExampleFileRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Import/deviceExampleFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Örnek olarak bir cihazlar excel dosyası indirmektedir.
     * Example Device Excel File
     */
    async getDeviceExampleFile(requestParameters: GetDeviceExampleFileRequest): Promise<Blob> {
        const response = await this.getDeviceExampleFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Excel dosyası olarak alınan cihaz listesindeki cihazları Device tablosuna ekler.
     * Import Device Excel File (Permission.Device.Add)
     */
    async postDeviceExcelFileRaw(requestParameters: PostDeviceExcelFileRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/iot/Import/DeviceExcelFile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Excel dosyası olarak alınan cihaz listesindeki cihazları Device tablosuna ekler.
     * Import Device Excel File (Permission.Device.Add)
     */
    async postDeviceExcelFile(requestParameters: PostDeviceExcelFileRequest): Promise<Blob> {
        const response = await this.postDeviceExcelFileRaw(requestParameters);
        return await response.value();
    }

}
